import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function BeachResort() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const notify = (success) => toast(success);
  const [disable, setDisable] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      setDisable(true);
      const response = await fetch('/api/Recovery/Create', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        console.log(formData);
        toast.error('Error While Submitting the form. Recheck your Information or Contact Custom Support!');
        setDisable(false);
      } else {
        // Handle success
        toast.success('Successfully Submitted. Our Team will contact you ASAP!')
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      }
      setTimeout(() => {
        setDisable(false);
      }, 3000);
    }
    else {
      setErrors(validationErrors);
    }
  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateForm = () => {
    let validationErrors = {};

    // Validate first name
    if (formData.name.trim() === "") {
      validationErrors.name = "Name is required";
    }

    // Validate email
    if (formData.email.trim() === "") {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email format";
    }

    // Validate phone
    if (formData.phone.trim() === "") {
      validationErrors.phone = "Phone number is required";
    }


    if (formData.message.trim() === "") {
      validationErrors.message = "Please enter some message";
    }

    // Add more validation rules for other fields...

    return validationErrors;
  };

  const [errors, setErrors] = useState({});
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://cancer-management.com/" />
      </Helmet>
      <div className="bgimg vh-100" style={{
        position: 'absolute',
        display: 'block',
        width: '100%'
      }}>
        <div className="overlay">
          <img src="assets/images/bg.jpg" className="vh-100 w-100" alt="" />
        </div>
      </div>
      <Header />

      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <ToastContainer />
            <div
              className="card"
              style={{ borderRadius: 15, minHeight: "620px" }}
            >
              <div className="card-body text-center">
                <div className="mt-3 mb-4">
                  <img
                    src="assets/images/profile.png"
                    className="rounded-circle img-fluid"
                    style={{ width: 150 }}
                  />
                </div>
                <h4 className="mb-2">Let's start your recovery now </h4>
                <p className="text-muted mb-4">
                  In Bahamas <span className="mx-2">|</span>{" "}
                  <span href="#!">(Nassau)</span>
                </p>

                <div className="row">
                  <div className="col-12">
                    <div class="mb-3">
                      <input
                        type="text"
                        className={`form-control ${errors.name ? "is-invalid" : ""
                          }`}
                        id="name"
                        name="name"
                        placeholder="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback" style={{ textAlign: 'left' }}>
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div class="mb-3">
                      <input
                        type="text"
                        className={`form-control ${errors.email ? "is-invalid" : ""
                          }`}
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="invalid-feedback" style={{ textAlign: 'left' }}>
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div class="mb-3">
                      <input
                        type="number"
                        className={`form-control ${errors.phone ? "is-invalid" : ""
                          }`}
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <div className="invalid-feedback" style={{ textAlign: 'left' }}>
                          {errors.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div class="mb-3">
                      <textarea
                        class="form-control"
                        placeholder="Message"
                        rows="6"
                        className={`form-control ${errors.message ? "is-invalid" : ""
                          }`}
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && (
                        <div className="invalid-feedback" style={{ textAlign: 'left' }}>
                          {errors.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      disabled={disable}
                      onClick={(e) => { handleSubmit(e) }}
                      type="button"
                      className="btn custom-button btn-primary"
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div
              className="card BeachContentCard"
              style={{ borderRadius: 15, minHeight: "620px" }}
            >
              <div className="card-body">
                <h4 className="mb-5 text-center">
                  What's included in this 30-day exclusive recovery package for
                  $ 35k USD{" "}
                </h4>
                <h6>TESTS, TREATMENTS & RECOVERY PROTOCOLS</h6>

                <ul className="m-0">
                  <li>All tests, diagnostics, and reports</li>
                  <li>
                    Seminars teaching about protocols for bodily repair,
                    recovery, and healing
                  </li>
                  <li>
                    All daily treatments (we plan according to your tests and needs as an
                    individual)
                  </li>
                  <li>15+ 1-on-1 therapy sessions</li>
                  <li>Massages and healing/recovery sessions</li>
                  <li>
                    Daily training sessions (we plan according to your tests and needs as an
                    individual)
                  </li>
                  <li>
                    Powerful individual IV (4 to 12 depending on recommendation
                    after tests)
                  </li>
                </ul>
                <h6 className="mt-3">HOTEL AND MEALS</h6>
                <ul className="m-0">
                  <li>Private hotel room</li>
                  <li>Free access to gym</li>
                  <li>All meals, smoothies, juices, and herbs</li>
                  <li>Activities and guided tour</li>
                </ul>
                <h6 className="mt-3">FOLLOW-UP & SUPPORT</h6>
                <ul className="m-0">
                  <li>12-month online follow-up guidance and support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
