import Home from "./component/Home/Home";
import BeachResort from "./component/BeachResort/BeachResort";
import Hospital from "./component/Hospital/Hospital";
import AboutUs from "./component/AboutUs/AboutUs";
import Recovery from "./component/Recovery/Recovery";
import Insurance from "./component/Insurance/Insurance";
import Academy from "./component/Academy/Academy";
import Module from "./component/Academy/Modules";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import "bootstrap/dist/css/bootstrap.min.css";
import stripePromise from "./stripe";
import Agents from "./component/Registrations/Agents";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/beach-resort" component={BeachResort} />
          <Route exact path="/hospital" component={Hospital} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/cancer-recovery-management-online" render={() => (
            <Elements stripe={stripePromise}>
              <Recovery />
            </Elements>
          )} />
          {/* <Route exact path="/cancer-recovery-management-online" component={Recovery} /> */}
          <Route exact path="/insurance" component={Insurance} />
          <Route exact path="/academy" component={Academy} />
          <Route exact path="/module/:id" component={Module} />
          <Route exact path="/agent" component={Agents} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
