import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import SupportModal from "../Modal/SupportModal";
import React from "react";
import LoginModal from "../Modal/LoginModal";

function SecondHeader() {
  const [modalShow, setModalShow] = React.useState(false);
  const [loginModalShhow, setloginModalShhow] = React.useState(false);
  return (
    <>
      <SupportModal show={modalShow} onHide={() => setModalShow(false)} />
      <LoginModal show={loginModalShhow} onHide={() => setloginModalShhow(false)} />
      <Navbar
        className="custom-nav custom-nav-second destkop-header"
        collapseOnSelect
        expand="lg"
        bg=""
        variant="dark"
      >
        <Container>
          <Navbar.Brand className="d-none" href="#home">
            React-Bootstrap
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
              <Link to="/hospital" className="nav-link">
                Hospitals
              </Link>
            </Nav>
            <Nav className="me-auto">
              <Navbar.Brand>
                <Link to="/">
                  <img
                    src="assets/images/logo.png"
                    style={{ maxWidth: "250px" }}
                    alt=""
                  />
                </Link>
              </Navbar.Brand>
            </Nav>
            <Nav>
              <Link to="/insurance" className="nav-link">
                Insurance
              </Link>
              <span
                to=""
                onClick={() => setModalShow(true)}
                className="nav-link"
              >
                Support
              </span>
              <span
                to=""
                onClick={() => setloginModalShhow(true)}
                className="nav-link  custom-button" style={{ color: 'white' }}
              >
                Academy
              </span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar
        className="custom-nav custom-nav-second mobile-header"
        collapseOnSelect
        expand="lg"
        bg=""
        variant="dark"
      >
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="assets/images/logo.png"
                style={{ maxWidth: "200px" }}
                alt=""
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
              <Link to="/hospital" className="nav-link">
                Hospitals
              </Link>
              <Link to="/insurance" className="nav-link">
                Insurance
              </Link>
              <span
                to=""
                onClick={() => setModalShow(true)}
                className="nav-link"
              >
                Support
              </span>
              <span
                to=""
                onClick={() => setloginModalShhow(true)}
                className="nav-link custom-button" style={{ color: 'white' }}
              >
                Academy
              </span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default SecondHeader;
