import { Modal, Button } from 'react-bootstrap';

import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ConfirmationModel(props) {
    const handleSubmit = async (event) => {

    };
    // Function to handle Yes button click
    const handleYes = () => {
        props.onHide(true);
    };

    // Function to handle No button click
    const handleNo = () => {
        props.onHide(false);
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="p-5">
                <div className="row">
                    <h6> do youreally want to close the Quiz? </h6><br />
                    by doing this you will not be able to continue from where you left off.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleYes}>Yes</Button>
                <Button variant="secondary" onClick={handleNo}>No</Button>
            </Modal.Footer>
        </Modal>
    );
}
