import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import SupportModal from "../Modal/SupportModal";
import React from "react";
import LoginModal from "../Modal/LoginModal";
function Header() {
  const [modalShow, setModalShow] = React.useState(false);
  const [loginModalShhow, setloginModalShhow] = React.useState(false);
  return (
    <>
      <SupportModal show={modalShow} onHide={() => setModalShow(false)} />
      <LoginModal show={loginModalShhow} onHide={() => setloginModalShhow(false)} />
      <Navbar
        className="custom-nav destkop-header"
        collapseOnSelect
        expand="lg"
        bg=""
        variant="dark"
      >
        <Container>
          <Navbar.Brand className="d-none" href="#home">
            React-Bootstrap
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
              <Link to="/hospital" className="nav-link">
                Hospitals
              </Link>
            </Nav>
            <Nav className="me-auto">
              <Navbar.Brand>
                <Link to="/">
                  {/* <img src="assets/images/logo.png" alt="" /> */}
                  <img src="assets/images/cancer-silver.png" width={'250'} height={'80'} alt="" />
                </Link>
              </Navbar.Brand>
            </Nav>
            <Nav>
              <Link to="/insurance" className="nav-link">
                Insurance
              </Link>
              <Link
                onClick={() => setModalShow(true)}
                to=""
                className="nav-link"
              >
                Support
              </Link>
              <Link
                onClick={() => setloginModalShhow(true)}
                to=""
                className="nav-link custom-button"
              >
                Academy
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar
        className="custom-nav mobile-header"
        collapseOnSelect
        expand="lg"
        bg=""
        variant="dark"
      >
        <Container>
          <Navbar.Brand className="" href="#home">
            <Link to="/">
              <img src="assets/images/cancer-silver.png" style={{ maxWidth: "200px" }} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
              <Link to="/hospital" className="nav-link">
                Hospitals
              </Link>
              <Link to="/insurance" className="nav-link">
                Insurance
              </Link>
              <Link
                onClick={() => setModalShow(true)}
                to=""
                className="nav-link"
              >
                Support
              </Link>
              <Link
                onClick={() => setloginModalShhow(true)}
                to=""
                className="nav-link custom-button"
              >
                Academy
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
