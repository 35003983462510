

import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './../Academy/academy.css'
import Header from "../Layout/Header";
import React, { useEffect, useState, useRef } from 'react';
import { Tooltip } from 'react-tooltip'
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
export default function Agents() {
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [listRegistrations, setListRegistrations] = useState(true);
    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        nameOfBank: "",
        email: "",
        routingNumber: "",
        iBAN: "",
        phone: "",
    });
    const [errorsList, setErrorsList] = useState({
        contacts: [{}], // Array of error objects, parallel to contacts
        // Other field errors...
    });
    const [formDatalist, setFormDatalist] = useState({
        contacts: [
            { firstName: '', lastName: '', email: '', phone: '' }
        ],
        hospitalName: "",
        agentName: "",
        agentNumber: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleListChange = (e) => {
        const { name, value } = e.target;
        setFormDatalist((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const imgRef = useRef(null);
    const handleDownload = async (FileName) => {
        toast.success(
            "Download Started. It may take a moment due to it's file size. ")
        const downloadFile = async (retryCount = 3) => {
            try {
                setIsLoading(true);
                const response = await fetch('/api/Files/' + FileName, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf'
                    }
                });

                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', FileName + '.pdf'); // Name of the file to be downloaded
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } else {
                    console.error('File download failed:', response.statusText);
                }
            } catch (error) {
                if (retryCount > 0) {
                    console.warn('Retrying download, attempts left:', retryCount);
                    await downloadFile(retryCount - 1);
                } else {
                    console.error('Error fetching file:', error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        await downloadFile();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        setErrors(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            console.log(formData);
            setDisable(true);
            const response = await fetch('/api/Agents/CancerAgentCreate', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                console.log(formData);
                toast.error('Error While Submitting the Application. Recheck your Information or Contact Custom Support!');
                setDisable(false);
            } else {
                // Handle success
                toast.success('Application Submitted Successfully! Our Team will contact you ASAP!')
                setFormData({
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    nameOfBank: "",
                    email: "",
                    routingNumber: "",
                    iBAN: "",
                    phone: "",
                });
            }
            setTimeout(() => {
                setDisable(false);
            }, 3000);
        }
        else {
            setErrors(validationErrors);
        }
    };
    const handleSubmitList = async (e) => {
        e.preventDefault();
        setDisable(true);
        const updatedContacts = [...formDatalist.contacts];
        const newErrors = [...errorsList.contacts];
        let allFieldsFilled = true;
        debugger
        // Check each contact for empty fields and set errors accordingly
        updatedContacts.forEach((contact, index) => {
            const contactErrors = {};
            if (!contact.firstName.trim()) {
                contactErrors.firstName = 'First name is required.';
                allFieldsFilled = false;
            }
            if (!contact.lastName.trim()) {
                contactErrors.lastName = 'Last name is required.';
                allFieldsFilled = false;
            }
            newErrors[index] = contactErrors;
        });

        const validationErrors = validateFormList();
        setErrorsList({ ...errorsList, contacts: newErrors });
        if (validationErrors.agentNumber) {
            allFieldsFilled = false;
            setErrorsList({ ...errorsList, agentNumber: validationErrors.agentNumber, contacts: newErrors })
        }
        else {
            setErrorsList({ ...errorsList, agentNumber: '', contacts: newErrors })
        }
        if (!allFieldsFilled) {
            setDisable(false);
            return;
        }
        debugger
        console.log(formDatalist);
        if (Object.keys(validationErrors).length === 0) {
            console.log(formData);
            setDisable(true);
            const response = await fetch('/api/Agents/CreateCancerRegistrations', {
                method: 'POST',
                body: JSON.stringify(formDatalist),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            debugger
            if (response.status == 400) {
                toast.error('Invalid Agent Number.  Recheck your Information or Contact Custom Support!');
                setDisable(false);
                return;
            }
            if (!response.ok) {
                console.log(formData);
                toast.error('Error While Submitting the Application. Recheck your Information or Contact Custom Support!');
                setDisable(false);
            } else {
                // Handle success
                toast.success('Application Submitted Successfully! Our Team will contact you ASAP!')
                setFormDatalist({
                    contacts: [
                        { firstName: '', lastName: '', email: '', phone: '' }
                    ],
                    hospitalName: "",
                    agentName: "",
                    agentNumber: "",
                });
            }
            setTimeout(() => {
                setDisable(false);
            }, 3000);
        }
        else {
            setErrors(validationErrors);
        }
    };
    const validateForm = () => {
        let validationErrors = {};

        // Validate first name
        if (formData.firstName.trim() === "") {
            validationErrors.firstName = "First Name is required";
        }
        if (formData.lastName.trim() === "") {
            validationErrors.lastName = "Last Name is required";
        }
        if (formData.email.trim() === "") {
            validationErrors.email = "Email is required";
        }
        else if (!isValidEmail(formData.email)) {
            validationErrors.email = "Invalid email format";
        }
        if (formData.phone.trim() === "") {
            validationErrors.phone = "Phone is required";
        }
        return validationErrors;
    };
    const validateFormList = () => {
        let validationErrors = {};

        // Validate first name
        if (formDatalist.agentNumber.trim() === "") {
            validationErrors.agentNumber = "Agent Number is required";
        }
        return validationErrors;
    };
    const isValidEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleContactChange = (index, field, value) => {
        // Update contact field value
        const updatedContacts = [...formDatalist.contacts];
        updatedContacts[index][field] = value;
        setFormDatalist({ ...formDatalist, contacts: updatedContacts });

        // Validate and set errors if any
        const newErrors = [...errorsList.contacts];
        if (value.trim() === '') {
            // Simple validation: field should not be empty
            newErrors[index] = { ...newErrors[index], [field]: 'This field is required.' };
        } else {
            // Clear error for the field
            newErrors[index] = { ...newErrors[index], [field]: '' };
        }
        setErrorsList({ ...errorsList, contacts: newErrors });
    };
    const addMoreContacts = () => {
        let allFieldsFilled = true;

        // Clone the current state to avoid direct mutation
        const updatedContacts = [...formDatalist.contacts];
        const newErrors = [...errorsList.contacts];

        // Check each contact for empty fields and set errors accordingly
        updatedContacts.forEach((contact, index) => {
            const contactErrors = {};
            if (!contact.firstName.trim()) {
                contactErrors.firstName = 'First name is required.';
                allFieldsFilled = false;
            }
            if (!contact.lastName.trim()) {
                contactErrors.lastName = 'Last name is required.';
                allFieldsFilled = false;
            }
            newErrors[index] = contactErrors;
        });

        // Only add a new contact if all fields in existing contacts are filled
        if (allFieldsFilled) {
            updatedContacts.push({ firstName: '', lastName: '', email: '', phone: '' });
            newErrors.push({}); // Add a new empty error object for the new contact
        }

        // Update state
        setFormDatalist({ ...formDatalist, contacts: updatedContacts });
        setErrorsList({ ...errorsList, contacts: newErrors });
    };

    return (
        <>
            <div className="bgimg vh-100">
                <div className="overlay">
                    {/* <img src="assets/images/bg.jpg" className="vh-100 w-100" alt="" /> */}
                </div>
                <ToastContainer />
                <Header />
                <div className="container" style={{ marginTop: "50px", position: 'relative', zIndex: '100000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {!listRegistrations &&
                        <div className="row my-5  pb-4 pl-4 pr-4" id="formHos" style={{ backgroundColor: 'white', borderRadius: '5px', width: '60%' }}>
                            <div className="col-lg-12 p-4">
                                <p style={{ textAlign: 'right' }}>
                                    <button
                                        style={{
                                            backgroundColor: "#4D8314",
                                            color: "white",
                                            padding: "10px 20px",
                                            fontSize: "16px",
                                            borderRadius: "5px",
                                            border: "none",
                                            cursor: "pointer"
                                        }}
                                        className="mb-2"
                                        onClick={() => { setListRegistrations(true) }}
                                    >
                                        List of Registrations this week
                                    </button>
                                </p>
                                <h5 style={{ textAlign: 'center' }}>Here you can register as a new agent for Cancer Management.</h5>
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-lg-4">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.firstName ? "is-invalid" : ""
                                                    }`}
                                                id="firstName"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                placeholder="First Name"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-4">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.middleName ? "is-invalid" : ""
                                                    }`}
                                                id="middleName"
                                                name="middleName"
                                                value={formData.middleName}
                                                onChange={handleChange}
                                                placeholder="Middle Name"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-4">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.lastName ? "is-invalid" : ""
                                                    }`}
                                                id="lastName"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-lg-6">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.email ? "is-invalid" : ""
                                                    }`}
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="mb-3">
                                            <input
                                                type="number"
                                                className={`form-control ${errors.phone ? "is-invalid" : ""
                                                    }`}
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                placeholder="Phone"
                                            />
                                        </div>
                                    </div>

                                    <h6 style={{ textAlign: 'center' }}>If you want us to wire your money to your bank account, we need all the information under.</h6>
                                    <div class="col-sm-12 col-lg-12 mt-4">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.nameOfBank ? "is-invalid" : ""
                                                    }`}
                                                id="nameOfBank"
                                                name="nameOfBank"
                                                value={formData.nameOfBank}
                                                onChange={handleChange}
                                                placeholder="Name of your Bank"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-12">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.routingNumber ? "is-invalid" : ""
                                                    }`}
                                                id="routingNumber"
                                                name="routingNumber"
                                                value={formData.routingNumber}
                                                onChange={handleChange}
                                                placeholder="Routing Number"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-12">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.iBAN ? "is-invalid" : ""
                                                    }`}
                                                id="iBAN"
                                                name="iBAN"
                                                value={formData.iBAN}
                                                onChange={handleChange}
                                                placeholder="IBAN or account Number"
                                            />
                                        </div>
                                    </div>
                                    <h6 style={{ textAlign: 'center' }}>If you use PayPal, we only need the email address for your PayPal account.</h6>
                                    <div class="col-12 text-center mt-2 ">
                                        <button disabled={disable} onClick={(e) => handleSubmit(e)} type="button" class="btn custom-button btn-primary">
                                            Send
                                        </button>
                                    </div>
                                    <h6 className='mt-2' style={{ textAlign: 'center' }}>Any questions or need to send us more information, use the Support form.</h6>
                                    <span className='mt-2' style={{ textAlign: 'center' }}>
                                        When you register at an Agent you agree with all the terms and conditions in the
                                        attached <a href="/api/Files/Agreement" target="_blank" style={{ textDecoration: 'underline', fontStyle: 'italic' }}>agreement</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        listRegistrations &&
                        <div className="row my-5  pb-4 pl-4 pr-4" id="formHos" style={{ backgroundColor: 'white', borderRadius: '5px', width: '60%' }}>
                            <div className="col-lg-12 p-4">
                                <p style={{ textAlign: 'right' }}>
                                    <button
                                        style={{
                                            backgroundColor: "#4D8314",
                                            color: "white",
                                            padding: "10px 20px",
                                            fontSize: "16px",
                                            borderRadius: "5px",
                                            border: "none",
                                            cursor: "pointer"
                                        }}
                                        className="mb-2"
                                        onClick={() => { setListRegistrations(false) }}
                                    >
                                        New agent? Register here!
                                    </button>
                                </p>
                                <h5 style={{ textAlign: 'center' }}>List of my registrations for this week.</h5>
                                <div class="row mt-4">
                                    {
                                        formDatalist.contacts.map((contact, index) => (
                                            <>
                                                <div class="col-sm-6 col-lg-3">
                                                    <div class="mb-3">
                                                        <input
                                                            type="text"
                                                            className={`form-control ${errorsList.contacts[index]?.firstName ? "is-invalid" : ""}`}
                                                            id={`firstName-${index}`}
                                                            name="firstName"
                                                            value={contact.firstName}
                                                            onChange={(e) => handleContactChange(index, 'firstName', e.target.value)}
                                                            placeholder="First Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-lg-3">
                                                    <div class="mb-3">
                                                        <input
                                                            type="text"
                                                            className={`form-control ${errorsList.contacts[index]?.lastName ? "is-invalid" : ""}`}
                                                            id={`lastName-${index}`}
                                                            name="lastName"
                                                            value={contact.lastName}
                                                            onChange={(e) => handleContactChange(index, 'lastName', e.target.value)}
                                                            placeholder="Last Name"
                                                        />
                                                    </div>
                                                </div>


                                                <div class="col-sm-6 col-lg-3">
                                                    <div class="mb-3">
                                                        <input
                                                            type="text"
                                                            className={`form-control ${errorsList.contacts[index]?.email ? "is-invalid" : ""}`}
                                                            id={`email-${index}`}
                                                            name="email"
                                                            value={contact.email}
                                                            onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                                                            placeholder="Email"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-lg-3">
                                                    <div class="mb-3">
                                                        <input
                                                            type="number"
                                                            className={`form-control ${errorsList.contacts[index]?.phone ? "is-invalid" : ""}`}
                                                            id={`phone-${index}`}
                                                            name="phone"
                                                            value={contact.phone}
                                                            onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
                                                            placeholder="Phone"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                    <div style={{ textAlign: 'right' }}>
                                        <button type="button" className="btn btn-link" onClick={addMoreContacts}>
                                            Add more
                                        </button>
                                    </div>
                                    <hr style={{ borderTop: '4px dotted grey' }} />
                                    <div class="col-sm-12 col-lg-12 mt-4">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errorsList.hospitalName ? "is-invalid" : ""
                                                    }`}
                                                id="hospitalName"
                                                name="hospitalName"
                                                value={formDatalist.hospitalName}
                                                onChange={handleListChange}
                                                placeholder="Hospital Name"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errorsList.agentName ? "is-invalid" : ""
                                                    }`}
                                                id="agentName"
                                                name="agentName"
                                                value={formDatalist.agentName}
                                                onChange={handleListChange}
                                                placeholder="Agent Name"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errorsList.agentNumber ? "is-invalid" : ""
                                                    }`}
                                                id="agentNumber"
                                                name="agentNumber"
                                                value={formDatalist.agentNumber}
                                                onChange={handleListChange}
                                                placeholder="Agent Number"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 text-center mt-2 ">
                                        <button disabled={disable} onClick={(e) => handleSubmitList(e)} type="button" class="btn custom-button btn-primary">
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <section>
                <div className="containerSection">
                    <div className="image-container">
                        <img ref={imgRef} className='imgBanner' src="/assets/images/Rollup.jpg" alt="Rollup Banner" />
                        <button type='button' disabled={isLoading} className="download-button" onClick={(e) => { handleDownload('Rollup') }}>Download</button>
                    </div>
                    <div className="details-container">
                        <h1 className='heading1'>Boost Your Sales With Our Eye-Catching
                            Banner!</h1>
                        <p>Dear Sales Agents,</p>
                        <p>We are excited to present this professionally designed rollup banner that will
                            help you attract more clients and promote our comprehensive 12-month
                            Cancer Recovery Program more effectively.</p>
                        <h3>Here are some reasons why you should use this banner:</h3>

                        <ul>
                            <li><strong>Grab Attention:</strong> The vibrant design and compelling message will
                                capture the interest of potential clients immediately.</li>
                            <li><strong>Promote Our Program:</strong> Showcase the benefits of our 12-month Cancer Recovery Program, highlighting the recovery options available.</li>
                            <li><strong>Increase Engagement:</strong> Use this banner at events, clinics, and
                                meetings to engage and provide them with essential information.</li>
                            <li><strong>Professional Image:</strong> Enhance your sales pitch with high-quality
                                marketing materials that reflect the professionalism of the services you
                                represent.</li>
                        </ul>
                        <p>Print this rollup and start making a greater impact on your sales.</p>
                        <p>(The size is 200 cm height x 85 cm width)</p>
                        <div style={{ border: '2px solid green', padding: '10px', width: '100%' }}>
                            <h4 style={{ textAlign: 'center' }}>Download and print this A5 flyer.</h4>
                            <p>Hand out this flyer to help people register on
                                www.cancer-management.com. Teach them how
                                to use the QR code to register online for our 12-
                                month cancer recovery program.</p>
                            <div style={{ width: '100%' }} className='row'>
                                <img className='col-md-6' src="/assets/images/Flyer1.jpg" alt="Flyer 1" />
                                <img className='col-md-6' src="/assets/images/Flyer2.jpg" alt="Flyer 2" />
                            </div>
                        </div>
                        <button type='button' disabled={isLoading} className="download-button" onClick={(e) => { handleDownload('A5Flyer') }}>Download</button>
                    </div>
                </div>
            </section >
        </>
    )
}