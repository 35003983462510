export default function Clients() {
  return (
    <>
      <section className="Clients">
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ marginBottom: "50px" }}>
              <div className="text-center">
                <h3 className="Heading">Clients Share Their Experience</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div
                className="card mb-3"
                style={{ borderRadius: 15, minHeight: "200px" }}
              >
                <div className="card-body">
                  <div className="mt-3 mb-4">
                    <img
                      src="assets/images/Image_5.jpg"
                      className="rounded-circle img-fluid"
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                        position: "absolute",
                        top: "-51px",
                      }}
                    />
                  </div>
                  <h4 className="mb-4">Sarah M. </h4>
                  <p>
                    "I can't thank this platform enough for guiding me through my cancer recovery journey. The personalized recovery plan tailored to my needs gave me the confidence to face each step. The hospital recommendations were invaluable, and even though I declined some treatments, the support was unwavering. I'm now cancer-free, and I owe a big part of my success to this amazing platform.".
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div
                className="card mb-3"
                style={{ borderRadius: 15, minHeight: "200px" }}
              >
                <div className="card-body">
                  <div className="mt-3 mb-4">
                    <img
                      src="assets/images/Image_6.jpg"
                      className="rounded-circle img-fluid"
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                        position: "absolute",
                        top: "-51px",
                      }}
                    />
                  </div>
                  <h4 className="mb-4">Dr. Michael S., Chief Oncologist, Hope Medical Center </h4>
                  <p>
                    "Partnering with this cancer management site has been a game-changer for our hospital. The platform's seamless communication with patients allows us to offer well-informed treatment suggestions. It's heartening to see patients making choices that align with their preferences, and we're proud to be part of their journey. Together, we're fostering patient-centric care."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div
                className="card mb-3"
                style={{ borderRadius: 15, minHeight: "200px" }}
              >
                <div className="card-body">
                  <div className="mt-3 mb-4">
                    <img
                      src="assets/images/Image_7.jpg"
                      className="rounded-circle img-fluid"
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                        position: "absolute",
                        top: "-51px",
                      }}
                    />
                  </div>
                  <h4 className="mb-4">Emily W., Director of Health Insurance, SecureCare Insurances </h4>
                  <p>
                    "Our collaboration with this platform has transformed how we approach patient care. The streamlined solutions have not only reduced costs but also ensured that patients receive timely, quality treatments. The platform's dedication to optimizing patient outcomes aligns perfectly with our goals. It's a win-win for both patients and our bottom line."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="text-center mt-2">Cancer Management uses Human Energy Healthcare educational platform as one of our digital tools to get results.</h4>
        <div className="text-center">
          <img
            height='150'
            width='520'
            className="mt-3"
            src="assets/images/LogoNew.png"
          />
        </div>
      </section>
    </>
  );
}
