import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function LoginModal(props) {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setFormErrors] = useState({});
    const [disable, setDisable] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Invalid email format';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        }
        setFormErrors(errors);
        setDisable(true);
        if (Object.keys(errors).length === 0) {
            // Call your API or perform other actions here
            const response = await fetch('/api/Login/GetPatients', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                setDisable(false);
                toast.error('Log in Failed!');
            } else {
                // Handle success
                setFormData({
                    email: '',
                    password: '',
                });
                toast.success('Logged in Successfully!');
                var dataJson = await response.json();
                localStorage.setItem('MT_ID', dataJson.p_Id)
                window.location.href = "/academy";
                setDisable(false);
                props.onHide(false);
            }
            setTimeout(() => {
                setDisable(false);
            }, 3000);
        } else {
            setDisable(false);
        }
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="p-3">
                <div className="row">
                    <div className="col-12 mb-4 text-center">
                        <h1>Log in</h1>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <input
                                type="email"
                                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                id="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="mb-3">
                            <input
                                type="password"
                                className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                id="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                        </div>
                    </div>

                    <div className="col-12 text-center">
                        <button
                            disabled={disable}
                            type="button"
                            className="btn w-100 custom-button btn-primary"
                            onClick={(e) => { handleSubmit(e) }}
                        >
                            Login
                        </button>
                    </div>
                    <ToastContainer />
                </div>
            </Modal.Body>
        </Modal>
    );
}
