export default function Benefits() {
  return (
    <>
      <section className="Benefits">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h3 className="Heading">Benefits</h3>
                <h5 className="subHeading mb-5">
                  Benefits with cancer-management.com
                </h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="text-center">
                <img
                  src="./assets/images/Image_1.jpg"
                  className="img-fluid img-circle"
                  alt=""
                />
                <h6 className="mt-3">Personalized Recovery Plans</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="text-center">
                <img
                  src="./assets/images/Image_2.jpg"
                  className="img-fluid img-circle"
                  alt=""
                />
                <h6 className="mt-3">Hospital Collaboration</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="text-center">
                <img
                  src="./assets/images/Image_3.jpg"
                  className="img-fluid img-circle"
                  alt=""
                />
                <h6 className="mt-3">Insurance Solutions</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="text-center">
                <img
                  src="./assets/images/Image_4.jpg"
                  className="img-fluid img-circle"
                  alt=""
                />
                <h6 className="mt-3">24/7 Support Team</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
