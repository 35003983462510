import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import Empowering from "./Section/Empowering";
import Benefits from "./Section/Benefits";
import Clients from "./Section/Clients";
import Helmet from "react-helmet";
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import ModalVideo from "../Academy/ModalVideo";
import './homes.css'

export default function Home() {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleDivClick = () => {
    history.push("/cancer-recovery-management-online");
  }
  const handleDiv2Click = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }
  return (
    <>


      <Helmet>
        <link rel="canonical" href="https://cancer-management.com/" />
        <title>Comprehensive Cancer Management Services | Expert Care and Treatment</title>
        <meta name="title" content="Comprehensive Cancer Management Services - Expert Care and Treatment" />
        <meta name="description" content=" Discover top-notch cancer management services tailored to your needs. Our expert team offers comprehensive care, cutting-edge treatments, and compassionate support. Take control of your health journey today." />
      </Helmet>

      <div className="bgimg vh-100">
        <div className="overlay">
          {/* <img src="assets/images/bg.jpg" className="vh-100 w-100" alt="" /> */}
        </div>

        <Header />
        <div className="container" style={{ marginTop: "50px" }}>
          <div className="row">
            <div className="col-md-12 mb-3 col-lg-6" style={{ cursor: 'pointer' }} onClick={handleDivClick}>
              <div className="card homecard">
                <div className="card-body">
                  <img
                    src="assets/images/support.png"
                    className="w-100"
                    alt=""
                  />
                  <h5 className="m-0">
                    <strong>12-MONTH CANCER RECOVERY ONLINE PROGRAM </strong>
                  </h5>
                </div>
                <div className="card-footer text-end">
                  <Link
                    to="/cancer-recovery-management-online"
                    className="btn custom-button btn-primary"
                  >
                    Start Today
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3 col-lg-6" style={{ cursor: 'pointer' }} onClick={handleDiv2Click}>
              <div className="card homecard">
                <div className="card-body">
                  <img src="assets/images/THMBVIDEO.png" style={{ objectFit: "fill" }} className="w-100" alt="" />
                  <img src="assets/images/Playmed.png" alt="" className="playbutton" />
                  <span className="buttonText">Play Video</span>
                  <h5 className="m-0">
                    <strong>
                      WATCH VIDEO BEFORE STARTING 12-MONTH PROGRAM
                    </strong>
                  </h5>
                </div>
                <div className="card-footer text-end">
                  <button
                    className="btn custom-button btn-primary"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Empowering />
      <Benefits />
      <ModalVideo id={'currentId'} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Clients />
      <Footer />
    </>
  );
}
