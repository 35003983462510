export default function Empowering() {
  return (
    <>
      <section className="Empowering">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <h3 className="Heading">Empowering Journey in Cancer Recovery</h3>
              <h5 className="subHeading mb-3">
                Join our comprehensive cancer recovery programs and take control
                of your health journey.
              </h5>
              <p className="description">
                Welcome to a place of empowerment and support on your cancer recovery journey. At Cancer-management.com, we are committed to guiding you through a transformative experience with our comprehensive cancer recovery programs. Join us as we provide you with essential information and resources, equipping you to take charge of your health journey. Our emerging program registrations offer invaluable insights, empowering patients to navigate the challenges of cancer recovery with confidence. Together, we'll help you gain control, find strength, and foster a community of understanding. Join our programs today and embark on a path towards renewed well-being.
              </p>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <img
                src="./assets/images/img1.jpeg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
