import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function SupportModal(props) {
  const [file, selectedFile] = React.useState(null);
  const [errors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    subject: '',
    email: '',
    message: '',
  });
  const [disable, setDisable] = useState(false);
  const changeFile = (event) => {
    var sfile = event.target.files;
    selectedFile(sfile[0])
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!formData.subject) {
      errors.subject = 'Subject is required';
    }
    if (!formData.message) {
      errors.message = 'must provide details';
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    setFormErrors(errors);
    setDisable(true);
    if (Object.keys(errors).length === 0) {
      var formDatataComplete = new FormData();
      formDatataComplete.append('json', JSON.stringify(formData))
      formDatataComplete.append('file', file)
      // Call your API or perform other actions here
      var a = props
      setDisable(true);
      const response = await fetch('/api/Support/Create', {
        method: 'POST',
        body: formDatataComplete,
      });

      if (!response.ok) {
        setDisable(false);
      } else {
        // Handle success
        setFormData({
          subject: '',
          email: '',
          message: '',
        });
        toast.success('Submmitted Successfully!');
        setDisable(false);
        props.onHide(false);
      }
      setTimeout(() => {
        setDisable(false);
      }, 3000);
    }
    else {
      setDisable(false);

    }
  };
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-5">
        <div className="row">
          <div className="col-12 mb-4 text-center">
            <h1>Contact Support</h1>
          </div>
          <div className="col-12">
            <div class="mb-3">
              <input
                type="text"
                className={`form-control ${errors.subject ? "is-invalid" : ""
                  }`}
                id="subject"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleInputChange}
              />
              {errors.subject && (
                <div className="invalid-feedback">
                  {errors.subject}
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div class="mb-3">
              <input
                type="text"
                className={`form-control ${errors.email ? "is-invalid" : ""
                  }`}
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className="invalid-feedback">
                  {errors.email}
                </div>
              )}
            </div>
          </div>

          <div className="col-12">
            <div class="mb-3">
              <textarea
                className={`form-control ${errors.message ? "is-invalid" : ""
                  }`}
                id="message"
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
              {errors.message && (
                <div className="invalid-feedback">
                  {errors.message}
                </div>
              )}
            </div>
          </div>

          <div className="col-12">
            <div class="mb-3">

              <input type="file" class="form-control" onChange={(e) => { changeFile(e) }} id="customFile" />
            </div>
          </div>

          <div className="col-12 text-center">
            <button
              disabled={disable}
              type="button"
              className="btn w-100 custom-button btn-primary"
              onClick={(e) => { handleSubmit(e) }}
            >
              Send
            </button>
          </div>
          <ToastContainer />
        </div>
      </Modal.Body>
    </Modal>
  );
}
