import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './academy.css'
import React, { useEffect, useState, useRef } from 'react';
import { Tooltip } from 'react-tooltip'
import { useHistory } from 'react-router-dom';
import ModalQuiz from './ModalQuiz';
import ModuleExcercise from './ModuleExcercise';
import Modal from 'react-modal';
const Module = (props) => {
    const bgImage = "url('/assets/images/BgImage.jpg')";
    const backIcon = "/assets/images/icons8-back-64.png";
    const trphyIcon = "/assets/images/trophy.png";
    const exerciseIcon = "/assets/images/chapter-exercise-01-48.png";
    const quizIcon = "/assets/images/classroom-quiz-48.png";
    const thumbnail = "/assets/images/";
    const checkIcon = "/assets/images/check-mark.png";
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isModalExcerciseOpen, setModalExcerciseOpen] = useState(false);
    const moduleId = useParams();
    const [moduleData, setModuleData] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    const [activeTopic, setActiveTopic] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const [modules, setModules] = useState([]);
    const [testResults, setResults] = useState(null);
    const [allowedQuiz, setAllowedQuiz] = useState(false);
    const [allowedExcercise, setAllowedExcercise] = useState(false);
    const [excerciseComplete, setExcerciseComplete] = useState(false);
    const [completedQuiz, setCompleteQuiz] = useState(false);
    let history = useHistory();
    function handleClick(module) {
        if (!module.disabled) {
            history.push(`/module/${module.moduleId}`);
        }
    }
    const getQuizLog = async () => {
        const response = await fetch(`/api/Course/GetQuizLog/${moduleId.id}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setCompleteQuiz(result.isComplete);
    }
    const getModuleContent = () => {
        var pId = localStorage.getItem('MT_ID');
        fetch(`/api/Course/GetModule/${moduleId.id}/${pId}`)
            .then(response => response.json())
            .then(data => {
                setModuleData(data)
            })
    }
    useEffect(() => {
        // Get the id from route parameters
        var pId = localStorage.getItem('MT_ID');
        const idFromRoute = props.match.params.id;
        setCurrentId(idFromRoute)
        fetch(`/api/Course/GetModule/${idFromRoute}/${pId}`)
            .then(response => response.json())
            .then(data => {
                setModuleData(data)
                setPdfUrl(`/api/Course/GetTopicFile/${data[0].fileName}`)
            })
            .catch(error => console.error('Error fetching module data:', error));
        // setCurrentId(idFromRoute);
        var pId = localStorage.getItem('MT_ID');
        fetch(`/api/Course/GetModules/${pId}`)
            .then(response => response.json())
            .then(data => setModules(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    const prevModuleId = useRef();
    useEffect(() => {
        if (moduleId && moduleId.id && moduleId.id !== prevModuleId.current) {
            var userID = localStorage.getItem('MT_ID');
            if (!userID) {
                window.location.href = '/';
            }
            prevModuleId.current = moduleId.id;
            setCurrentId(moduleId.id);
            var pId = localStorage.getItem('MT_ID');
            // Assuming the API endpoint to fetch a specific module looks like /api/Course/GetModule/{id}
            fetch(`/api/Course/GetModule/${moduleId.id}/${pId}`)
                .then(response => response.json())
                .then(data => {
                    setModuleData(data)
                    setPdfUrl(`/api/Course/GetTopicFile/${data[0].fileName}`)
                    setTimeout(() => {
                        setActiveTopic(data[0].contentId)
                        submitLogs(data[0].contentId, true, moduleId.id);
                        getQuizLog();
                    }, 100);
                })
                .catch(error => console.error('Error fetching module data:', error));
            fetch(`/api/Course/GetQuizResult/${moduleId.id}/${userID}`)
                .then(response2 => response2.json())
                .then(data1 => {
                    if (data1.highestMarks != 0 && data1.total != 0) {
                        setResults(data1);
                    }
                    else {
                        setResults(null);
                    }
                })
                .catch(error => console.error('Error fetching module data:', error));
            fetch(`/api/Course/GetQuestions/${moduleId.id}`)
                .then(response3 => response3.json())
                .then(data2 => {
                    if (data2.length > 0) {
                        setAllowedQuiz(true);
                    }
                    else {
                        setAllowedQuiz(false);
                    }
                })
                .catch(error => console.error('Error fetching module data:', error));
            var PID = localStorage.getItem('MT_ID');
            fetch(`/api/Course/GetExcercises/${moduleId.id}/${PID}`)
                .then(response4 => response4.json())
                .then(data3 => {
                    if (data3.length > 0) {
                        setAllowedExcercise(true);
                    }
                    else {
                        setAllowedExcercise(false);
                    }
                })
                .catch(error => console.error('Error fetching module Exzcercise data:', error));
            var PID = localStorage.getItem('MT_ID');
            fetch(`/api/Course/GetExcerciseComplete/${moduleId.id}/${PID}`)
                .then(response5 => response5.json())
                .then(data4 => {
                    if (data4) {
                        setExcerciseComplete(true);
                    }
                    else {
                        setExcerciseComplete(false);
                    }
                })
                .catch(error => console.error('Error fetching module Exzcercise data:', error));
        }
    }, [moduleId]);
    const submitLogs = async (topicId, isTopic, moduleId) => {
        var url = `/api/Course/SubmitLog`
        // Define the parameters you want to pass in the body
        var parameters = {
            topicId: topicId,
            isModuleTopic: isTopic,
            moduleId: moduleId,
            p_ID: localStorage.getItem('MT_ID')
            // ... add more parameters as needed
        };
        var body = JSON.stringify(parameters);
        await fetch(url, {
            method: 'POST', // or 'PUT' if you are updating data
            headers: {
                'Content-Type': 'application/json'
                // Add any other headers you need here
            },
            body: body
        }).then(response => response.json())
            .then(d => {
                getModuleContent();
                console.log('sadfasdf');
            })

    }

    const handleTopicClick = (topicId, contentId) => {
        setPdfUrl(`/api/Course/GetTopicFile/${topicId}`);
        setActiveTopic(contentId);
        submitLogs(contentId, true, currentId)
    }

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    return (
        <div className="bgStyle" style={{ backgroundImage: bgImage }}>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        margin: 'auto',
                        width: '100%',
                        height: '100%',
                        overflow: 'unset',
                        padding: '0',
                        border: 'none',
                        background: 'none'
                    }
                }}
            >
                <div style={{ position: 'relative', height: '100%' }}>
                    <button
                        onClick={closeModal}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1,
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '2px',
                            padding: '10px'
                        }}
                    >
                        Close
                    </button>
                    {pdfUrl && (
                        <iframe
                            id='myIframe'
                            src={pdfUrl + '#pagemode=none'}
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        />
                    )}
                </div>
            </Modal>
            <h1 className='titleStyle mb-4 mt-4'>{moduleData.length > 0 ? moduleData[0].moduleName : ''}</h1>
            <Link to={`/academy`}>
                <img src={backIcon} alt="" width='50' height='50' style={{ position: 'fixed', top: '30px', left: '30px' }} />
            </Link>
            <Tooltip id="my-tooltip" className="react-tooltip" place="top" type="dark" effect="solid" />
            <div className="moduleContent  mt-3 row  ml-3 mr-3" style={{ marginLeft: '1rem', marginRight: '1rem' }} >
                <div className="mediaPlayer pr-2 col-md-8" >
                    <div className='row'>
                        <div className='col-md-2'>


                        </div>
                        <div className='col-md-10'>
                            <span onClick={openModal}
                                style={{ float: 'right', border: '0px', border: 'none', backgroundColor: 'white' }}>
                                <svg data-tooltip-id="my-tooltip" data-tooltip-content="Full Screen" width="35" height="34"
                                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23 4C23 2.34315 21.6569 1 20 1H16C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V4Z" fill="#0F0F0F" />
                                    <path d="M23 16C23 15.4477 22.5523 15 22 15C21.4477 15 21 15.4477 21 16V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 21.4477 15 22C15 22.5523 15.4477 23 16 23H20C21.6569 23 23 21.6569 23 20V16Z" fill="#0F0F0F" />
                                    <path d="M4 21H8C8.55228 21 9 21.4477 9 22C9 22.5523 8.55228 23 8 23H4C2.34315 23 1 21.6569 1 20V16C1 15.4477 1.44772 15 2 15C2.55228 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21Z" fill="#0F0F0F" />
                                    <path d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8L3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 2.55228 9 2C9 1.44772 8.55228 1 8 1H4C2.34315 1 1 2.34315 1 4V8Z" fill="#0F0F0F" />
                                </svg>
                            </span>

                        </div>
                    </div>

                </div>
            </div>
            <div className="moduleContent  mt-3 row  ml-3 mr-3" style={{ marginLeft: '1rem', marginRight: '1rem' }} >

                <div className="mediaPlayer pr-2 col-md-8" >
                    <div className='row'>
                        <div className='col-md-2 text-center ' style={{ fontSize: '17px', fontWeight: '800' }}>
                            {modules.map(module => (
                                module.disabled == true ? <div data-tooltip-id="my-tooltip" data-tooltip-content="Module Locked: Complete Previous Module & Wait for Previous Month's Duration" style={{ border: '2px solid grey', }} className='marginTopModules p-2'>
                                    M {module.moduleNumber}
                                </div>
                                    :
                                    currentId == module.moduleId ?
                                        <div onClick={(e) => handleClick(module)} style={{ border: '3px solid Black', cursor: 'pointer' }} className='marginTopModules p-2'>
                                            M {module.moduleNumber}
                                        </div>
                                        :
                                        <div onClick={(e) => handleClick(module)} style={{ border: '2px solid Black', cursor: 'pointer' }} className='marginTopModules p-2'>
                                            M {module.moduleNumber}
                                        </div>
                            ))}

                        </div>

                        <div className='col-md-10'>
                            {pdfUrl && !modalIsOpen ? <iframe id='myIframe' src={pdfUrl + '#pagemode=none'} width="100%" height="700px" /> : <></>}
                        </div>
                    </div>
                    {/* Display the PDF */}


                </div>
                <div className="topicsList ml-1 col-md-4" style={{ paddingLeft: '0px', paddingRight: '0px' }} >
                    <h6 className='text-center pt-2 headingStyles pb-2'>List of Content</h6>
                    <div className='topicsListUnder'>
                        {moduleData.length > 0 ? moduleData.map((topic, index) => (
                            <div

                                className={`topicContent ${activeTopic === topic.contentId ? 'active' : ''}`}
                                key={index} onClick={() => handleTopicClick(topic.fileName, topic.contentId)} style={{ cursor: 'pointer', marginBottom: '10px', position: 'relative' }}>
                                {/* Thumbnail */}
                                <img
                                    src={thumbnail + topic.thumbnail}
                                    alt={`${topic} thumbnail`}
                                    style={{
                                        width: '90px',
                                        height: '63px',
                                        borderRadius: '10px',
                                        marginRight: '10px',
                                        float: 'left'
                                    }}
                                />

                                {/* Topic Title */}
                                <p style={{ padding: '0px', marginBottom: '2px', fontSize: '12px' }}><b>{topic.topicTitle}</b> </p>

                                {/* Topic Description */}
                                <p style={{ fontSize: '12px', fontFamily: 'Roboto' }}>{topic.description}</p>

                                {/* Clear the float */}
                                <div style={{ clear: 'both' }}></div>
                                <div>
                                    {topic.isComplete && <img height='30' width='30' style={{ position: 'absolute', bottom: '5px', right: '8px' }} src={checkIcon} alt="" />}
                                </div>
                            </div>
                        )) : <></>}
                    </div>
                    <div style={{ textAlign: 'center', color: 'green', border: '2px solid black', position: 'relative', fontSize: '18px', backgroundColor: 'white', cursor: 'pointer', position: 'relative' }} className='pt-3 pb-3 mt-2'>Quiz
                        <br />
                        {completedQuiz && <img height='50' width='50' style={{ position: 'absolute', top: '13px', right: '8px' }} src={checkIcon} alt="" />}
                        {allowedQuiz && <img onClick={() => { setModalOpen(true) }} src={quizIcon} alt="" width='50' height='50' />}
                        {!allowedQuiz && <img src={quizIcon} alt="" width='50' height='50' />}
                        {testResults && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '20px'
                            }}
                                onClick={() => { setModalOpen(true) }}>
                                Highest Marks :
                                {testResults.highestMarks} / {testResults.total}
                                <button className='start-button-quiz'
                                    style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        bottom: '4px',
                                        fontSize: '16px'
                                    }}>Start Quiz</button>
                                {testResults.highestMarks === testResults.total && <img height='30' width='30' style={{ position: 'absolute', right: '50px' }} src={trphyIcon} alt="" />}
                            </div>
                        )}
                    </div>
                    <div onClick={() => { setModalExcerciseOpen(allowedExcercise ? true : false) }} style={{ cursor: 'pointer', position: 'relative', textAlign: 'center', color: 'green', border: '2px solid black', fontSize: '18px', backgroundColor: 'white' }} className='pt-3 pb-3 mt-2'>Exercises
                        <br />
                        {allowedExcercise && <img src={exerciseIcon} alt="" width='50' height='50' />}
                        {!allowedExcercise && <img src={exerciseIcon} alt="" width='50' height='50' />}
                        {
                            excerciseComplete &&
                            <div>
                                <img height='30' width='30' style={{ position: 'absolute', bottom: '5px', right: '8px' }} src={checkIcon} alt="" />
                            </div>
                        }
                        {/* <img src={exerciseIcon} alt="" width='50' height='50' /> */}
                    </div>
                    {/* Sample topics - adjust as needed */}

                </div>
                <ModalQuiz id={currentId} isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
                <ModuleExcercise id={currentId} isOpen={isModalExcerciseOpen} onClose={() => setModalExcerciseOpen(false)} />
            </div>
        </div >
    );
}

export default Module;