import SecondHeader from "../Layout/SecondHeader";
import SecondFooter from "../Layout/SecondFooter";
import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './hospitals.css'
import ModalVideo from "../Academy/ModalVideo";
import ModalVideoCare from "../Academy/ModalVideoCare";
export default function Hospital() {
  const [formData, setFormData] = useState({
    name: "",
    numberofRepresentative: "",
    email: "",
    webpage: "",
    country: "",
    phone: "",
    numberofEmployees: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('3');
  const [selectedPackgedError, setSelectedError] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSelect = (value) => {
    setSelectedPackage(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedPackage == null) {
      setSelectedError("Please Select License");
    }
    else {
      setSelectedError(null);
    }
    const validationErrors = validateForm();
    if (selectedPackage == null) {
      return
    }
    else {
      setFormData({ ...formData, licence: selectedPackage })
    }
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      console.log(formData);
      setDisable(true);
      const response = await fetch('/api/Hospital/Create', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        console.log(formData);
        toast.error('Error While Submitting the Application. Recheck your Information or Contact Custom Support!');
        setDisable(false);
      } else {
        // Handle success
        toast.success('Application Submitted Successfully! Our Team will contact you ASAP!')
        setFormData({
          name: "",
          numberofRepresentative: "",
          email: "",
          webpage: "",
          country: "",
          phone: "",
          numberofEmployees: "",
          description: "",
        });
        setSelectedPackage(null)
      }
      setTimeout(() => {
        setDisable(false);
      }, 3000);
    }
    else {
      setErrors(validationErrors);
    }
  };
  const validateForm = () => {
    let validationErrors = {};

    // Validate first name
    if (formData.name.trim() === "") {
      validationErrors.name = "Hospital name is required";
    }

    // Validate last name
    if (formData.numberofRepresentative.trim() === "") {
      validationErrors.numberofRepresentative = "Name of Representative is required";
    }

    // Validate email
    if (formData.email.trim() === "") {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email format";
    }
    if (formData.phone.trim() === "") {
      validationErrors.phone = "Phone is required";
    }
    if (formData.country.trim() === "") {
      validationErrors.country = "Country is required";
    }
    if (formData.description.trim() === "") {
      validationErrors.description = "Description is required";
    }
    // Validate phone
    // Validate card holder name


    // Validate card number




    // Add more validation rules for other fields...

    return validationErrors;
  };

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleDiv2Click = () => {
    setIsModalOpen(true);
  }
  return (
    <>
      <SecondHeader />
      <ToastContainer />
      <ModalVideoCare id={'currentId'} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <section className="hospital">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 col-lg-12">
              <h2 className="mt-5">
                HIGH QUALITY ONCOLOGY CARE FOR HOSPITALS
              </h2>
              <h5 className="mb-4" style={{ fontWeight: "normal" }}>
                Cancer Management is the preferred partner for hospitals globally. You can apply for a partnership as a hospital representative.
              </h5>
              <a className="btn custom-button mb-4 btn-primary" href="#formHos">
                Apply here for partnership
              </a>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12 col-lg-6">
              <img
                src="assets/images/cancer.jpg"
                style={{ borderRadius: "20px", height: '100%' }}
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-12 col-lg-6" style={{ cursor: 'pointer' }} onClick={handleDiv2Click}>
              <div className="card homecard" style={{ border: '2px solid green' }}>
                <div className="card-body">
                  <img src="assets/images/THMBVIDEO.png" style={{ objectFit: "fill" }} className="w-100" alt="" />
                  <img src="assets/images/Playmed.png" alt="" className="playbutton" />
                  <span className="buttonText">Play Video</span>
                  <h5 className="m-0">
                    <strong>
                      We Provide Modern Cancer Care for your hospital
                    </strong>
                  </h5>
                </div>
                <div className="card-footer text-end">
                  <button className="btn custom-button btn-primary">
                    Learn More
                  </button>
                </div>
              </div>
            </div>

          </div>
          {/* <div className="row mb-5">
            <div className="col-md-12 col-lg-6">
              <h2 className=" mt-5">
                HIGH QUALITY ONCOLOGY CARE FOR HOSPITALS
              </h2>
              <h5 className="mb-4" style={{ fontWeight: "normal" }}>
                Cancer Management is the preferred partner for hospitals
                globally. You can apply for a partnership as a hospital representative.
              </h5>
              <a class="btn custom-button mb-4 btn-primary" href="#formHos">
                Apply here for partnership
              </a>
            </div>
            <div className="col-md-12 col-lg-6">
              <img
                src="assets/images/cancer.jpg"
                style={{ borderRadius: "20px" }}
                className="w-100"
                alt=""
              />
            </div>
          </div> */}
          <div className="row mb-4">
            <div className="col-12">
              <hr style={{ borderColor: "#4d8314" }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-3">
                Here are some reasons why you should recommend your patients start on our 12-month online cancer recovery program.{" "}
              </h3>
              <ul className="m-0 mb-4">
                <li>
                  When oncologists recommend avoiding chemotherapy and radiation because a patient’s body is too weak.
                </li>
                <li>
                  When your treatments do not provide any tangible results for the patient.
                </li>
                <li>
                  When patients refuse chemotherapy but still need treatment.
                </li>
                <li>
                  When a patient wants natural and modern treatments with no side effects.
                </li>
                <li>
                  When your patients need to recover and regain strength after chemotherapy.
                </li>
              </ul>
              <p>
                We recommend patients start as early as possible. This will help make their immune systems stronger, give them more energy, and overall, a healthier state of body and mind. Importantly, this can help reduce side effects from chemotherapy and radiation.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h3 className="mb-1">People demand better results, and we’re here to help you achieve it </h3>
              <p>
                Thanks to the greater availability of accessible information, there is an increased emphasis on tangible results in healthcare. This has posed both a challenge and a concern for numerous hospitals. Patients don’t always readily accept the recommendations of their doctors, as they recognize that big health-related decisions directly impact their own lives. Consequently, many individuals are actively exploring alternative solutions to chemotherapy and radiation treatments.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-1">Our body’s self-healing capacity is very impressive </h3>
              <p>
                Extensive scientific research, combined with our experience, has shown unequivocally that our bodies have inherent systems and resources capable of fighting most forms of cancer. However, in cases where healing is required for more challenging or advanced stages of cancers, the presence of specific factors becomes essential to help the body achieve complete recovery.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-1">
                Increases chances surviving cancer significantly
              </h3>
              <p>
                We believe that by working with the right knowledge and modern technology, the chances of surviving cancer can, in some cases, increase from under 5% to over 90%. Our first client, a health worker who worked in a hospital for more than 30 years, was told she had cancer in 4 internal organs and was given 4 months to live. Her doctors said that no method or treatment in the world could save her. She is still alive, more than 20 years later. We cannot guarantee similar results. But we have evolved and learned much in the last 2 decades. Today, we have more knowledge and better tools and technology available. We will gladly use these to serve, teach, and train you and your clients.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-1">
                We recommend starting with a recovery program with Cancer Management
              </h3>
              <p>
                Radiation and chemotherapy sometimes cause severe side effects and unnecessary suffering. Use of these methods can damage a patient’s nervous system, inner organs, digestive system, and immune system. This underscores the importance of critically evaluating and exploring alternative approaches, or a combination of treatments, that prioritize efficacy while also minimizing potential harm. Patients’ safety must always be your priority.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-1">A new era in cancer treatment that also reduces burnout among doctors and nurses</h3>
              <p>
                We offer a comprehensive educational program tailored to your doctors and nurses. This new knowledge can help them understand patients better, and at the same time learn how to stay healthy themselves to avoid burning out.
                We are here to help your employees become more skilled at their jobs. Let's look together at the best approach based on the specific needs of your staff and patients.
              </p>
            </div>
          </div>
          <div className="row my-5" id="formHos">
            <div className="col-lg-8 offset-lg-2">
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.name ? "is-invalid" : ""
                        }`}
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Hospital Name"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.numberofRepresentative ? "is-invalid" : ""
                        }`}
                      id="numberofRepresentative"
                      name="numberofRepresentative"
                      value={formData.numberofRepresentative}
                      onChange={handleChange}
                      placeholder="Name of representative"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.email ? "is-invalid" : ""
                        }`}
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.webpage ? "is-invalid" : ""
                        }`}
                      id="webpage"
                      name="webpage"
                      value={formData.webpage}
                      onChange={handleChange}
                      placeholder="Website"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="mb-3">
                    <input
                      type="number"
                      className={`form-control ${errors.phone ? "is-invalid" : ""
                        }`}
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.country ? "is-invalid" : ""
                        }`}
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      placeholder="Country"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="mb-3">
                    <input
                      type="number"
                      className={`form-control ${errors.numberofEmployees ? "is-invalid" : ""
                        }`}
                      id="numberofEmployees"
                      name="numberofEmployees"
                      value={formData.numberofEmployees}
                      onChange={handleChange}
                      placeholder="Number of employees"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <textarea
                      className={`form-control ${errors.description ? "is-invalid" : ""
                        }`}
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Tell us about your hospital and your preferences for help and guidance."
                      rows="6"
                    ></textarea>
                  </div>
                </div>
                <div className="pricing">
                  <PackageBox
                    title="License 1 – Hospital"
                    patients="Unlimited cancer patients annually"
                    price="$25000/monthly"
                    selected={selectedPackage === '1'}
                    onSelect={() => handleSelect('1')}
                  />
                  <PackageBox
                    title="License 2 – Hospital"
                    patients="Up to 1000 cancer patients annually"
                    price="$10000/monthly"
                    selected={selectedPackage === '2'}
                    onSelect={() => handleSelect('2')}
                  />
                  <PackageBox
                    title="License 3 – Hospital"
                    patients="Up to 100 cancer patients annually"
                    price="$2500/monthly"
                    selected={selectedPackage === '3'}
                    onSelect={() => handleSelect('3')}
                  />
                </div>
                {selectedPackgedError && <p className="mt-2" style={{ color: 'red' }}>{selectedPackgedError}</p>}
                <div class="col-12 text-center mt-2 ">
                  <button disabled={disable} onClick={(e) => handleSubmit(e)} type="button" class="btn custom-button btn-primary">
                    Send Application
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p>
            By submitting this application, you agree to all our terms and conditions. When Cancer Management accepts your application, the agreement is binding for both parties, and we use this date as the activation date for our partnership.
          </p>
          <ul className="m-0 mb-4">
            <li>
              The license includes education for your employees.
            </li>
            <li>
              The license has a minimum commitment of 12 months, after which it converts to a rolling monthly agreement.
            </li>
          </ul>
          <p>
            You can read and download our agreement along with our terms and conditions <a href="/api/Files/GetLicence" target="_blank"> here</a>
          </p>
        </div>
      </section>
      <SecondFooter />
    </>
  );
}
const PackageBox = ({ title, patients, price, additional, selected, onSelect }) => (
  <div className={`package ${selected ? 'selected' : ''}`} onClick={onSelect}>
    <h6 className={`selectedClass ${selected ? '' : 'hiddenClass'}`}>Selected</h6>
    <h6>{title}</h6>
    <p>{patients}</p>
    <p className="Additional">{price}</p>

  </div>
);