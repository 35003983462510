/* eslint-disable react-hooks/rules-of-hooks */
import ConfirmationModel from '../Modal/confirmationModel';
import './academy.css'
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';


const ModuleExcercise = ({ id, isOpen, onClose }) => {
    if (!isOpen) return null;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const submitLogs = async (moduleId, excerciseName) => {
        var url = `/api/Course/SubmitLog`
        // Define the parameters you want to pass in the body
        var parameters = {
            isModuleExcercise: true,
            moduleId: moduleId,
            excerciseName: excerciseName,
            p_ID: localStorage.getItem('MT_ID')

            // ... add more parameters as needed
        };
        var body = JSON.stringify(parameters);
        await fetch(url, {
            method: 'POST', // or 'PUT' if you are updating data
            headers: {
                'Content-Type': 'application/json'
                // Add any other headers you need here
            },
            body: body
        }).then(response => response.json())
            .then(d => {
                console.log('sadfasdf');
            })

    }
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {
        if (isOpen) {
            const fetchData = async () => {
                setLoading(true);
                var PID = localStorage.getItem('MT_ID');
                try {
                    const response = await fetch(`/api/Course/GetExcercises/${id}/${PID}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const result = await response.json();
                    setData(result);

                    if (result.length > 0) {
                        submitLogs(id, result[0].excerciseName);
                    }
                    console.log()
                } catch (e) {
                    setError(e.message);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [isOpen]);


    const showConfirmation = () => {
        onClose()
    };
    async function handleHEHRedirection(urlForHEH) {
        var url = `/api/Course/RedirectionTokenForEnergy`
        var userID = localStorage.getItem('MT_ID');
        var parameters = {
            userId: userID,
            urlForHEH: urlForHEH,
        };
        setLoading(true);
        var body = JSON.stringify(parameters);
        const response = await fetch(url, {
            method: 'POST', // or 'PUT' if you are updating data
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        debugger
        if (data.token) {
            setLoading(false);
            window.open('https://humanenergyhealthcare.com' + urlForHEH + '&secure=' + encodeURIComponent(data.token) + '&userId=' + userID, '_blank');
        }
    }
    function ApplyFunction() {
        const handleClick = event => {
            const buttonName = event.target.name;
            const url = buttonName;
            console.log('Fetching URL:', url);
            handleHEHRedirection(url);
            // Optionally, perform fetch operation here
            // fetch(url).then(response => console.log(response));
        };

        // Select all buttons whose ID starts with 'btn_Click_'
        const buttons = document.querySelectorAll('button[id^="btn_Click_"]');

        // First remove any existing event listeners to avoid duplicates
        buttons.forEach(button => {
            button.removeEventListener('click', handleClick);
        });

        // Add new click event listeners
        buttons.forEach(button => {
            button.addEventListener('click', handleClick);
        });

        // Cleanup function to remove event listeners
        return () => {
            buttons.forEach(button => {
                button.removeEventListener('click', handleClick);
            });
        };
    }
    const setSelectedTabs = (id, excerciseName, index) => {
        setSelectedTab(index);

        setTimeout(() => {
            if (data[index].type == 2) {
                ApplyFunction();
            }
            if (data[index] && data[index].values) {
                var jsonData = JSON.parse(data[index].values);
                debugger
                Object.keys(jsonData).forEach(key => {
                    if (document.getElementById(key) != null) {
                        document.getElementById(key).value = jsonData[key];
                    }
                });
            }
        }, 1000);
        submitLogs(id, excerciseName)
    };
    const saveHTML = async (excerciseId) => {
        // Define the parameters you want to pass in the body
        var container = document.getElementById("HTMLExcercise");

        // Get the inner HTML content
        var innerHTMLContent = container.innerHTML;

        // Create a temporary element to parse inner HTML
        var tempElement = document.createElement('div');
        tempElement.innerHTML = innerHTMLContent;

        // Find all input elements
        var inputElements = tempElement.querySelectorAll('input');

        // Iterate over input elements to get their values
        var inputValues = {};
        inputElements.forEach(function (input) {
            inputValues[input.id] = document.getElementById(input.id).value;
        });
        setLoading(true)
        var parameters = {
            html: JSON.stringify(inputValues),
            id: excerciseId,
            p_ID: localStorage.getItem('MT_ID')

            // ... add more parameters as needed
        };
        var body = JSON.stringify(parameters);
        await fetch(`/api/Course/UpdateExcercise`, {
            method: 'POST', // or 'PUT' if you are updating data
            headers: {
                'Content-Type': 'application/json'
                // Add any other headers you need here
            },
            body: body
        }).then(response => response.json())
            .then(d => {
                setLoading(false)
            })

    };
    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        margin: 'auto',
                        width: '100%',
                        height: '100%',
                        overflow: 'unset',
                        padding: '0',
                        border: 'none',
                        background: 'none'
                    }
                }}
            >
                <div style={{ position: 'relative', height: '100%' }}>
                    <button
                        onClick={closeModal}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1,
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '2px',
                            padding: '10px'
                        }}
                    >
                        Close
                    </button>
                    {data &&
                        data[selectedTab] && <div style={{ height: '100%' }}>
                            {data[selectedTab].type == 1 &&
                                <div style={{ height: '100%' }}>
                                    {data[selectedTab].path && <iframe src={`/api/Course/GetTopicFile/${data[selectedTab].path}` + '#pagemode=none'} width="100%" style={{ height: '100%' }} />}
                                </div>
                            }
                            {data[selectedTab].type == 2 &&
                                <div style={{ backgroundColor: 'white', height: '100%' }} >
                                    {
                                        data[selectedTab].html && <div id='HTMLExcercise' dangerouslySetInnerHTML={{ __html: data[selectedTab].html }}></div>
                                    }
                                </div>
                            }
                        </div>}
                </div>
            </Modal>
            {!modalIsOpen && <div className="modal-backdrop">

                <div className="modal-content-Quiz">
                    {<button onClick={showConfirmation} className="close-button">X</button>}
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: {error}</p>}
                    {data && <div style={{ position: 'relative', marginTop: '20px' }}>
                        <div style={{ position: 'relative', marginTop: '20px' }}>
                            <div className="tabs">
                                {data.map((item, index) => (
                                    <button
                                        key={index}
                                        className={`tab-item ${index === selectedTab ? 'active' : ''}`}
                                        onClick={() => setSelectedTabs(id, item.excerciseName, index)}
                                    >
                                        {item.excerciseName}
                                    </button>
                                ))}
                            </div>
                            <span onClick={openModal} className='mb-2'
                                style={{ border: '0px', border: 'none', backgroundColor: 'white' }}>
                                <svg data-tooltip-id="my-tooltip" data-tooltip-content="Full Screen" width="35" height="34"
                                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23 4C23 2.34315 21.6569 1 20 1H16C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V4Z" fill="#0F0F0F" />
                                    <path d="M23 16C23 15.4477 22.5523 15 22 15C21.4477 15 21 15.4477 21 16V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 21.4477 15 22C15 22.5523 15.4477 23 16 23H20C21.6569 23 23 21.6569 23 20V16Z" fill="#0F0F0F" />
                                    <path d="M4 21H8C8.55228 21 9 21.4477 9 22C9 22.5523 8.55228 23 8 23H4C2.34315 23 1 21.6569 1 20V16C1 15.4477 1.44772 15 2 15C2.55228 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21Z" fill="#0F0F0F" />
                                    <path d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8L3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 2.55228 9 2C9 1.44772 8.55228 1 8 1H4C2.34315 1 1 2.34315 1 4V8Z" fill="#0F0F0F" />
                                </svg>
                            </span>
                            <div className="tab-content mt-2">
                                {data[selectedTab] && <div>
                                    {data[selectedTab].type == 1 &&
                                        <div>
                                            {data[selectedTab].path && <iframe src={`/api/Course/GetTopicFile/${data[selectedTab].path}` + '#pagemode=none'} width="100%" height="700px" />}
                                        </div>
                                    }
                                    {data[selectedTab].type == 2 &&
                                        <div >
                                            {
                                                data[selectedTab].html && <div id='HTMLExcercise' dangerouslySetInnerHTML={{ __html: data[selectedTab].html }}></div>
                                            }
                                        </div>
                                    }
                                </div>}
                            </div>
                            <div>
                                {/* {data[selectedTab].type == 2
                                    &&
                                    <button disabled={loading}
                                        onClick={() => {
                                            saveHTML(data[selectedTab].id)
                                        }}
                                        style={{ backgroundColor: 'green', float: 'right', marginTop: '10px', color: 'white' }}
                                        className={`tab-item `}
                                    >
                                        Save
                                    </button>} */}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>}
        </>
    );
};
export default ModuleExcercise;