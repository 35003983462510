import React from "react";
function SecondFooter() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="SecondFooter" style={{ height: '250px', backgroundColor: '#f8f9fa' }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="text-center mt-5 mb-3" style={{ fontSize: "18px" }}>
                © All rights reserved CANCER MANAGEMENT - {currentYear}
              </h4>
              <div className="text-center">
                <p className="mt-3">Join us and help us grow. Connect with us now!</p>
                <button
                  style={{
                    backgroundColor: "#4D8314",
                    color: "white",
                    padding: "10px 20px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer"
                  }}
                  className="mb-2"
                  onClick={() => window.location.href = '/agent'}
                >
                  Agent
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default SecondFooter;
