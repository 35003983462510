import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './academy.css'
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
const Academy = () => {
    const bgImage = "url('./assets/images/BgImage.jpg')";
    const backIcon = "/assets/images/icons8-back-64.png";
    const checkIcon = "/assets/images/icons8-check-96.png";

    const [modules, setModules] = useState([]);
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    useEffect(() => {
        // Fetching data on component mount
        var pId = localStorage.getItem('MT_ID');
        fetch(`/api/Course/GetModules/${pId}`)
            .then(response => response.json())
            .then(data => setModules(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    function handleClick(module) {
        if (!module.disabled) {
            history.push(`/module/${module.moduleId}`);
        }
    }
    async function handleHEHRedirection(urlForHEH) {
        var url = `/api/Course/RedirectionTokenForEnergy`
        var userID = localStorage.getItem('MT_ID');
        var parameters = {
            userId: userID,
            urlForHEH: urlForHEH,
        };
        setLoading(true);
        var body = JSON.stringify(parameters);
        const response = await fetch(url, {
            method: 'POST', // or 'PUT' if you are updating data
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        debugger
        if (data.token) {
            setLoading(false);
            window.open('https://humanenergyhealthcare.com' + urlForHEH + '&secure=' + encodeURIComponent(data.token) + '&userId=' + userID, '_blank');
        }
    }

    return (
        <div className="bgStyle" style={{ backgroundImage: bgImage }}  >
            <a href='/'>
                <div style={{ position: 'fixed', top: '30px', left: '30px', background: 'white', color: 'black', padding: '10px', borderRadius: '3px', border: '1px solid lightgray' }} >
                    <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 12H3.62" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.85 8.6499L2.5 11.9999L5.85 15.3499" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span style={{ marginLeft: '10px' }}>
                        Logout

                    </span>
                </div>

            </a >
            <Tooltip id="my-tooltip" className="react-tooltip" place="top" type="dark" effect="solid" />
            <h1 className='titleStyle mb-4 mt-4'>Cancer Management Learning Program</h1>
            {/* Iterate over the modules and render each module's details inside a containerBox */}
            {
                modules.map(module => (
                    <div key={module.moduleId} style={{ cursor: 'pointer', position: 'relative' }} onClick={(e) => handleClick(module)} className="containerBox mt-3" >
                        {module.disabled ? (
                            <div className="playButton mt-3" data-tooltip-id="my-tooltip" data-tooltip-content="Module Locked: Complete Previous Module & Wait for Previous Month's Duration">
                                <img height={'70px'} width={'70px'} src="./assets/images/icons8-play-disabled-96.png" alt="Play Button" />
                            </div>
                        ) : (
                            <Link to={`/module/${module.moduleId}`}>
                                <div className="playButton mt-3" title="Click to Play!">

                                    <img height={'70px'} width={'70px'} src="./assets/images/icons8-play-96.png" alt="Play Button" />
                                </div>
                            </Link>
                        )}
                        {/* <Link to={`/module/${module.moduleId}`}>
                        <div className="playButton mt-3">
                            <img height={'70px'} width={'70px'} src="./assets/images/icons8-play-96.png" alt="Play Button" />
                        </div>
                    </Link> */}
                        <div className='headingSection mt-4'>
                            <h5>Module {module.moduleNumber + ' - ' + module.moduleName}</h5>
                            <p className='moduleDescription' style={{ width: '85%' }}>
                                {module.description}
                            </p>
                        </div>
                        <div className="bottomText">{module.percentComplete}% Completed</div>
                        {module.moduleComplete &&
                            <>
                                {
                                    module.buttonName != null && <> <button onClick={(e) => {
                                        e.stopPropagation(); handleHEHRedirection(module.buttonURL) // Stop the click event from bubbling up to the parent div
                                        // Call the specific function for the button here, if any
                                    }} className='buttonRedirect' style={{ position: "absolute", bottom: '5px', right: '8px' }}>{module.buttonName}
                                    </button>
                                    </>
                                }
                                <img height='30' width='30' style={{ position: 'absolute', top: '10px', right: '8px' }} src={checkIcon} alt="" />
                            </>
                        }
                    </div>
                ))
            }
        </div >
    );
}

export default Academy;