import SecondHeader from "../Layout/SecondHeader";
import SecondFooter from "../Layout/SecondFooter";
import { ToastContainer, toast } from 'react-toastify';
import React, { useState, useEffect, useRef  } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import stripePromise from './../../stripe';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
export default function Recovery() {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [TpId, setTP] = useState(null);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const Tp = queryParams.get('Tp'); // Get the 'Tp' query parameter
    setTP(Tp);
    if (Tp) {
      // Call the endpoint if 'Tp' exists
      fetch(`/api/Order/GetMT/${Tp}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setData(data)
          if (regFormRef.current) {
            regFormRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        })
        .catch((error) => setError(error.message));
    }
  }, [location.search]);
  const [resetElements, setResetElements] = useState(false);
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    address: "",
    country: "",
    city: "",
    cardHolderName: "",
    cardNumber: "",
    exp_Date: "",
    cVC: "",
    paymentFrom: "",
    token: '',
  });
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState({});
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState('');
  const regFormRef = useRef(null); // Reference to the div with id 'regForm'
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors)
    debugger
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    if (Object.keys(validationErrors).length === 0) {
      const cardElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        console.log('[error]', error);
      } else {
        console.log('[PaymentMethod]', paymentMethod);
        setFormData((prevFormData) => ({
          ...prevFormData,
          'token': paymentMethod.id,
          'tp': TpId
        }));
        // Here you can call your backend endpoint to process the payment
      }
      console.log(formData);
      var formDataCopy = { ...formData, token: paymentMethod.id,
        'tp': TpId
       }
      debugger
      setDisable(true);
      const response = await fetch('/api/Order', {
        method: 'POST',
        body: JSON.stringify(formDataCopy),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        console.log(formData);
        toast.error('Error While Submitting the Application. Recheck your Information or Contact Custom Support!');
        setDisable(false);
      } else {
        // Handle success
        toast.success('Successfully Started Your Program! Our Team will contact you ASAP!')
        setResetElements(true);
        setTimeout(() => setResetElements(false), 0);
        setFormData({
          fName: "",
          lName: "",
          email: "",
          phone: "",
          address: "",
          country: "",
          city: "",
          cardHolderName: "",
          cardNumber: "",
          exp_Date: "",
          cVC: "",
          paymentFrom: "",
        });
      }
      setTimeout(() => {
        setDisable(false);
      }, 3000);
    }
    else {
      setErrors(validationErrors);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  function handleCardNumberChange(event) {
    const input = event.target.value;
    let formattedInput = input.replace(/[^0-9]/g, ''); // remove non-numeric characters
    formattedInput = formattedInput.slice(0, 16)
    formattedInput = formattedInput.replace(/(.{4})/g, '$1 '); // insert space after every 4 characters
    formattedInput = formattedInput.trim();
    setCardNumber(formattedInput);
    setFormData({
      ...formData,
      cardNumber: formattedInput
    });
  }
  function handleExpiryDateChange(event) {
    const input = event.target.value;
    let formattedInput = input.replace(/[^0-9]/g, ''); // remove non-numeric characters
    if (formattedInput.length > 2) {
      formattedInput = formattedInput.slice(0, 2) + '/' + formattedInput.slice(2, 6); // insert slash after month
    }
    setExpiryDate(formattedInput);
    setFormData({
      ...formData,
      exp_Date: formattedInput
    });
  }
  const validateForm = () => {
    let validationErrors = {};

    // Validate first name
    if (formData.fName.trim() === "") {
      validationErrors.fName = "First name is required";
    }

    // Validate last name
    if (formData.lName.trim() === "") {
      validationErrors.lName = "Last name is required";
    }

    // Validate email
    if (formData.email.trim() === "") {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email format";
    }

    // Validate phone
    if (formData.phone.trim() === "") {
      validationErrors.phone = "Phone number is required";
    }

    // Validate card holder name


    // Validate card number
    if (formData.cardNumber === "" || !formData.cardNumber) {
      validationErrors.cardNumber = "Card number is required";
    }

    // Validate expiration date
    if (formData.exp_Date === "" || !formData.exp_Date) {
      validationErrors.exp_Date = "Expiration date is required";
    }

    // Validate CVC
    if (formData.cVC === "" || !formData.cVC) {
      validationErrors.cVC = "CVC is required";
    }

    // Add more validation rules for other fields...

    return validationErrors;
  };

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidCardNumber = (cardNumber) => {
    // Card number validation logic
    // Implement your own card number validation logic here
    return true;
  };

  const isValidexp_Date = (exp_Date) => {
    // Expiration date validation logic
    // Implement your own expiration date validation logic here
    return true;
  };
  const handleCardChange = (e, field) => {
    const valueObjct = e.complete;
    if (valueObjct) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: true,
      }));
    }
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: false,
      }));
    }
  }

  const isValidCVC = (cvc) => {
    // CVC validation logic
    // Implement your own CVC validation logic here
    return true;
  };
  return (
    <>
      <Helmet>
        <title>12-Month Cancer Recovery Management Program | Comprehensive Support & Healing</title>
        <link rel="canonical" href="https://cancer-management.com/12-month-recovery" />
        <meta name="description" content="Join our 12 month cancer recovery management program designed to provide comprehensive support and facilitate healing. Expert care, personalised plans, and a path towards a brighter, healthier future." />
        <meta name="title" content="12 Month Cancer Recovery Management Program - Comprehensive Support & Healing" />
      </Helmet>
      <SecondHeader />
      <section className="hospital">
        <div className="container">
          <div className="row mt-5">
            <div className="col-12">
              <h3 className="mb-3">
                12-MONTH EDUCATIONAL PROGRAM WITH CANCER MANAGEMENT
              </h3>
              <ul className="m-0 mb-4 f-22">
                <li>Support on a mental, emotional, and physical level. </li>
                <li>Learn methods that can increase survival by 50%. </li>
                <li>New knowledge on how to effectively fight diseases.</li>
                <li>
                  Individualized nutritional advice and support based on your
                  needs.
                </li>
                <li>
                  Second opinion and assistance when you are uncertain on next
                  step(s).
                </li>
                <li>Learn about pain management and modern pain relief solutions.</li>
              </ul>
            </div>
          </div>

          <div className="row my-5" id="regForm" ref={regFormRef}>
          {TpId != null && data != null && <div className="text-center"> 
              <img src={data.mT_Image_Name}  style={{borderRadius:'100px'}} height="120" width="120" /> 
              <h3>
                 {data.mT_Fname} {data.mT_Lname}
              </h3>
              <h5 className="mt-2 mb-2">{data.mT_Fname} {data.mT_Lname}, has invited you to join Global Doctors online health platform! Take charge of your health and happiness, and begin your journey to a vibrant and healthier you.</h5>
          </div>
          }
            <h4 className="text-center mb-3 mt-2">Fill out the form and begin your journey to recovery.</h4>
            <div className="col-lg-8 offset-lg-2">
              <div className="card">
                <div className="card-body">
                  <form className="mt-3" method="post">
                    <div className="row">
                      <div class="col-sm-12 col-lg-6">
                        <div class="mb-3">
                          <input
                            type="text"
                            className={`form-control ${errors.fName ? "is-invalid" : ""
                              }`}
                            id="fName"
                            name="fName"
                            placeholder="First Name"
                            value={formData.fName}
                            onChange={handleChange}
                          />
                          {errors.fName && (
                            <div className="invalid-feedback">
                              {errors.fName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <div class="mb-3">
                          <input
                            type="text"
                            className={`form-control ${errors.lName ? "is-invalid" : ""
                              }`}
                            id="lName"
                            name="lName"
                            placeholder="Last Name"
                            value={formData.lName}
                            onChange={handleChange}
                          />
                          {errors.lName && (
                            <div className="invalid-feedback">
                              {errors.lName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <div class="mb-3">
                          <input
                            type="text"
                            className={`form-control ${errors.email ? "is-invalid" : ""
                              }`}
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <div class="mb-3">
                          <input
                            type="text"
                            className={`form-control ${errors.phone ? "is-invalid" : ""
                              }`}
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                          {errors.phone && (
                            <div className="invalid-feedback">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <div class="mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Address"
                          />
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Country"
                            name="Country"
                          />
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            name="City"
                            placeholder="City"
                          />
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="mb-3">
                          <div
                            style={{
                              backgroundColor: "#f0f0f0",
                              padding: "10px",
                            }}
                            className="head d-flex align-items-center justify-content-between"
                          >
                            <div>
                              <span>Credit Card Payment <b>Secured by Stripe</b></span>
                            </div>
                            <div>
                              <span className="me-2">
                                <img
                                  style={{ maxWidth: "40px" }}
                                  src="assets/images/visa.jpg"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  style={{ maxWidth: "40px" }}
                                  src="assets/images/master.png"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <CardElement /> */}
                      <label>
                        Card number
                        <div style={{
                          border: errors.cardNumber ? '1px solid red' : '1px solid #ccc',
                          borderRadius: '4px', padding: '10px', marginTop: '8px', marginBottom: '20px'
                        }}>
                          <CardNumberElement
                            key={resetElements ? 1 : 0} options={ELEMENT_OPTIONS}
                            onChange={(e) => handleCardChange(e, 'cardNumber')} />
                        </div>
                      </label>
                      <div className="row">
                        <div className="col-md-4">
                          <label style={{ width: '100%' }}>
                            Expiration date
                            <div style={{
                              border: errors.exp_Date ? '1px solid red' : '1px solid #ccc',
                              padding: '10px', borderRadius: '4px', marginTop: '8px', marginBottom: '20px'
                            }}>
                              <CardExpiryElement
                                key={resetElements ? 1 : 0}
                                options={ELEMENT_OPTIONS}
                                onChange={(e) => handleCardChange(e, 'exp_Date')} />
                            </div>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label style={{ width: '100%' }}>
                            CVC
                            <div style={{
                              border: errors.cVC ? '1px solid red' : '1px solid #ccc',
                              padding: '10px', borderRadius: '4px', marginTop: '8px', marginBottom: '20px'
                            }}>
                              <CardCvcElement
                                key={resetElements ? 1 : 0}
                                options={ELEMENT_OPTIONS}
                                onChange={(e) => handleCardChange(e, 'cVC')} />
                            </div>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label style={{ width: '100%' }}>
                            <div style={{ border: '1px solid #ccc', padding: '7px', borderRadius: '4px', marginTop: '33px', marginBottom: '20px' }}>
                              2500$
                              {/* <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                value='2500$ USD'
                                disabled
                                readOnly
                              /> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      {/* <div class="col-3">
                        <div class="mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            value='2500$ USD'
                            disabled
                            readOnly
                          />
                        </div>
                      </div> */}
                      <div class="col-12 text-center">
                        <button onClick={(e) => { handleSubmit(e) }}
                          type="button"
                          disabled={disable}
                          class="btn custom-button btn-primary"
                        >
                          Start Your Program
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row my-5">
            <div className="col-lg-8 offset-lg-2">
              <h5 className="mb-3 text-center">
                Once you start, you will get access to a complete health
                platform. We will follow you month by month on your road to
                recovery.
              </h5>
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div
                    className="card"
                    style={{ borderRadius: 15, minHeight: "200px" }}
                  >
                    <div className="card-body text-center">
                      <div className="mt-3 mb-4">
                        <img
                          src="assets/images/exercise.jpg"
                          className="rounded-circle img-fluid"
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <h4 className="mb-2">Exercise </h4>
                      <p>
                        Learn how exercise can work like medicine for more than
                        thirty ailments and diseases
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div
                    className="card"
                    style={{ borderRadius: 15, minHeight: "200px" }}
                  >
                    <div className="card-body text-center">
                      <div className="mt-3 mb-4">
                        <img
                          src="assets/images/organs.jpg"
                          className="rounded-circle img-fluid"
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <h4 className="mb-2">Internal Organs </h4>
                      <p>
                        Learn how to train your internal organs to be more resilient
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>


      <SecondFooter />
    </>
  );
}
