import SecondHeader from "../Layout/SecondHeader";
import SecondFooter from "../Layout/SecondFooter";
import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Insurance() {
    const [formData, setFormData] = useState({
        name: "",
        contactPerson: "",
        email: "",
        webpage: "",
        phone: "",
        description: "",
    });
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState('3');
    const [selectedPackgedError, setSelectedError] = useState(null);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleSelect = (value) => {
        setSelectedPackage(value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedPackage == null) {
            setSelectedError("Please Select License");
        }
        else {
            setSelectedError(null);
        }
        const validationErrors = validateForm();
        if (selectedPackage == null) {
            return
        }
        else {
            setFormData({ ...formData, licence: selectedPackage })
        }
        setErrors(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            console.log(formData);
            setDisable(true);
            const response = await fetch('/api/Insurance/Create', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                console.log(formData);
                toast.error('Error While Submitting the Application. Recheck your Information or Contact Custom Support!');
                setDisable(false);
            } else {
                // Handle success
                toast.success('Application Submitted Successfully! Our Team will contact you ASAP!')
                setFormData({
                    name: "",
                    contactPerson: "",
                    email: "",
                    webpage: "",
                    phone: "",
                    description: "",
                });
            }
            setTimeout(() => {
                setDisable(false);
            }, 3000);
        }
        else {
            setErrors(validationErrors);
        }
    };
    const validateForm = () => {
        let validationErrors = {};
        if (formData.name.trim() === "") {
            validationErrors.name = "Hospital name is required";
        }
        if (formData.contactPerson.trim() === "") {
            validationErrors.contactPerson = "Number ofRepresentative is required";
        }
        if (formData.email.trim() === "") {
            validationErrors.email = "Email is required";
        } else if (!isValidEmail(formData.email)) {
            validationErrors.email = "Invalid email format";
        }
        if (formData.phone.trim() === "") {
            validationErrors.phone = "Phone is required";
        }
        if (formData.description.trim() === "") {
            validationErrors.description = "Description is required";
        }
        return validationErrors;
    };

    const isValidEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <>
            <SecondHeader />
            <ToastContainer />
            <section className="hospital">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="mb-3 mt-2">
                                Insurance companies save more lives by using our cancer recovery programs.
                            </h3>
                            <p>But even more important.
                            </p>
                            <ul className="m-0 mb-4">
                                <li>Faster recovery saves time and money.</li>
                                <li>Lower cost on tests and treatments.</li>
                                <li>Satisfied customers and higher chances of survival.</li>
                                <li>Reduce people’s suffering and pain from side effects after chemotherapy and radiation.</li>
                            </ul>
                            <p className="mb-5">
                                We have learned that the most effective approach involves a combination where patients start as
                                early as possible with our 12-month cancer recovery online program while simultaneously being
                                treated by their doctor or hospital.
                            </p>
                             <p className="mb-5">
                                By leveraging our excellent leadership skills, we aim to provide invaluable support throughout the
                                process, helping doctors, nurses, and patients make well-informed decisions. Our comprehensive
                                digital healthcare system further ensures the availability of critical real-time feedback to facilitate
                                optimal outcomes.
                            </p>

                            <h3 className="mb-3 mt-2">
                                Exclusive programs for insurance companies globally.
                            </h3>
                            <p >
                                We offer exclusive services and programs for insurance companies. Our unique knowledge and
                                experience, together with the use of modern technology and tools available to create a well-functioning healing process, make us the leading supplier and preferred partner.

                            </p>
                            <h4>12-month cancer recovery online program.</h4>
                            <p >
                                This is an online education and support program. The program is suitable for anyone who has been
                                diagnosed with cancer and is over 18 years of age. Patients can start as soon as they have a
                                diagnosis, and the program can be taken alongside or after the treatments.
                            </p>
                            <p >
                                Your insurance company can choose the license type below, depending on the number of
                                customers you have. As soon as your application has been processed, we will provide you with the
                                information you need to proceed.
                            </p>
                            <h3 className="mb-3 text-center">
                                You can apply below for a partnership as a representative of an insurance company.
                            </h3>
                            <div className="text-center">
                                <a
                                    href="#formInds"
                                    style={{ width: "30%" }}
                                    class="btn mb-4 custom-button btn-primary"
                                >
                                    Apply here for partnership
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h3 className="mb-3">
                                Situations where a patient with a cancer diagnosis uses Cancer Management recovery programs.
                            </h3>
                            <ul className="m-0 mb-4">
                                <li>
                                    When oncologists recommend avoiding chemotherapy because a patient’s body is too
                                    weak for chemotherapy or radiation.
                                </li>
                                <li>
                                    When chemotherapy or radiation do not provide any results for the patient.
                                </li>
                                <li>
                                    When patients refuse chemotherapy and radiation.                </li>
                                <li>
                                    When your patients need to recover and regain strength after chemotherapy.

                                </li>
                            </ul>
                            <p className="mb-5">
                                The recovery program will make patient's immune systems stronger, give them more energy, and an
                                overall healthier state of body and mind. Hence, this increases the chances for them to recover and
                                survive from any other treatments, including chemotherapy and radiation.
                            </p>
                            <p>
                                In certain cases, it is necessary to activate not only the physiological systems but also the mental
                                abilities that contribute to the body's healing process. Our primary focus lies in stimulating the
                                activation of individuals' own immune systems and latent healing powers that are not active in
                                everyday life.
                            </p>
                            <p>
                                We believe that by working with the right knowledge and modern technology, the chances of
                                surviving cancer increase significantly.
                            </p>
                            <p>
                                Radiation and chemotherapy sometimes cause serious side effects and unnecessary suffering.
                                Using these methods can damage a patient's nervous system, internal organs, digestive system,
                                and immune system. A report published in The Lancet, a renowned medical journal, highlighted a
                                worrying fact: over 50% of individuals who underwent chemotherapy found that their bodies
                                succumbed to the negative effects and toxicity, dying from the treatments, and not from cancer.
                            </p>
                            <p>
                                This emphasizes the importance of exploring alternative approaches to cancer treatments and
                                adopting/combining methods that are health-promoting and strengthening, with no risk of harm.

                            </p>
                            <p>
                                We are convinced that prioritizing education and treatments that focus 100% on the patient’s well-
                                being and following do no harm principles is the best approach to achieving lasting results. By
                                following these fundamental principles, we can collectively ensure the highest standards of care
                                and together make significant advances in medicine.
                            </p>
                            <p>
                                This approach is in line with the ethical and moral obligations inherent in the medical profession.
                            </p>
                        </div>
                    </div>

                    <div className="row my-5" id="formInds">
                        <div className="col-lg-8 offset-lg-2">
                            <h3 className="mb-5 text-center">
                                APPLICATION FOR INSURANCE COMPANIES. <br /> Get the benefits of a
                                partnership with Cancer Management.
                            </h3>
                            <div class="row">
                                <div class="col-12">
                                    <div class="mb-3">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.name ? "is-invalid" : ""
                                                }`}
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Insurance Company Name"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <div class="mb-3">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.contactPerson ? "is-invalid" : ""
                                                }`}
                                            id="contactPerson"
                                            name="contactPerson"
                                            value={formData.contactPerson}
                                            onChange={handleChange}
                                            placeholder="Name of representative or contact"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <div class="mb-3">
                                        <input
                                            type="number"
                                            className={`form-control ${errors.phone ? "is-invalid" : ""
                                                }`}
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}

                                            placeholder="Phone"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <div class="mb-3">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.webpage ? "is-invalid" : ""
                                                }`}
                                            id="webpage"
                                            onChange={handleChange}
                                            name="webpage"
                                            value={formData.webpage}
                                            placeholder="Website"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <div class="mb-3">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.email ? "is-invalid" : ""
                                                }`}
                                            id="email"
                                            name="email"
                                            onChange={handleChange}
                                            value={formData.email}
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-3">
                                        <textarea
                                            className={`form-control ${errors.description ? "is-invalid" : ""
                                                }`}
                                            id="description"
                                            onChange={handleChange}
                                            name="description"
                                            value={formData.description}
                                            placeholder="Tell us about your interest in becoming a partner with Cancer Management, and how many patients
                      you think might be interested in either one of our programs."
                                            rows="6"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="pricing">
                                    <PackageBox
                                        title="License 1 – Insurance"
                                        patients="Unlimited cancer patients annually"
                                        price="$25000/monthly"
                                        selected={selectedPackage === '1'}
                                        onSelect={() => handleSelect('1')}
                                    />
                                    <PackageBox
                                        title="License 2 – Insurance"
                                        patients="Up to 1000 cancer patients annually"
                                        price="$10000/monthly"
                                        selected={selectedPackage === '2'}
                                        onSelect={() => handleSelect('2')}
                                    />
                                    <PackageBox
                                        title="License 3 – Insurance"
                                        patients="Up to 100 cancer patients annually"
                                        price="$2500/monthly"
                                        selected={selectedPackage === '3'}
                                        onSelect={() => handleSelect('3')}
                                    />
                                </div>
                                {selectedPackgedError && <p className="mt-2" style={{ color: 'red' }}>{selectedPackgedError}</p>}
                                <div class="col-12 mt-2 text-center">
                                    <button type="button" disabled={disable} onClick={(e) => { handleSubmit(e) }} class="btn custom-button btn-primary">
                                        Send Application
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondFooter />
        </>
    );
}
export const PackageBox = ({ title, patients, price, additional, selected, onSelect }) => (
    <div className={`package ${selected ? 'selected' : ''}`} onClick={onSelect}>
        <h6 className={`selectedClass ${selected ? '' : 'hiddenClass'}`}>Selected</h6>
        <h6>{title}</h6>
        <p>{patients}</p>
        <p className="Additional">{price}</p>
    </div>
);