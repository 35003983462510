/* eslint-disable react-hooks/rules-of-hooks */
import './academy.css'
import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

const ModalVideoCare = ({ id, isOpen, onClose }) => {
    if (!isOpen) return null;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const videoRef = useRef(null);

    const handleDiv2Click = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }
    useEffect(() => {
        if (videoRef.current) {
            // videoRef.current.play()
            //     .catch(error => {
            //         console.error('Error playing the video:', error);
            //     });
        }
    }, [isModalOpen]);
    return (
        <div className="modal-backdrop">
            <div className="modal-content-Quiz">
                <div style={{ width: '100%' }}>
                    {/* <iframe width="100%" height="600" src="https://www.youtube.com/embed/3XLZsx18HVs?si=kFHYzgBmbKuo01VL" title="Cancer Management" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoPlay={true} allowFullScreen></iframe> */}
                    <ReactPlayer
                        width="100%"
                        height="600px"
                        className="videoFrame"
                        // url="https://www.youtube.com/embed/3XLZsx18HVs?si=kFHYzgBmbKuo01VL&amp;controls=0"
                        url="https://www.youtube.com/embed/3XLZsx18HVs?si=0IvpTjtkeZcYTIZu&amp;controls=0"
                        // url="https://gdaassets.blob.core.windows.net/videos/compressed-video-cancer-management-hospitals.m3u8"
                        controls
                        playing
                        autoplay={true}
                        ref={videoRef}
                    />
                    {/* <video controls ref={videoRef}>
                        <source src="/assets/images/Videos/compressed-video-cancer-management-hospitals.m3u8" type="video/mp4"></source>
                    </video> */}
                    {/* <iframe
                        width="100%"
                        height="600" // Set the height as needed
                        src="https://gdaassets.blob.core.windows.net/videos/Video Cancer Management - HOSPITALS.mp4"
                        title="Video title"
                        frameBorder="0"
                        allowFullScreen>
                    </iframe> */}
                </div>
                <button className='start-button mt-2' onClick={onClose}>Close</button>
            </div>
        </div>
    );
};
export default ModalVideoCare;