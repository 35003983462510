import SecondHeader from "../Layout/SecondHeader";
import SecondFooter from "../Layout/SecondFooter";
import { Link } from "react-router-dom/cjs/react-router-dom";
export default function AboutUs() {
  const checkIcon = "/assets/images/Cance11r.png";
  return (
    <>
      <SecondHeader />
      <section className="About">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 class="mt-5 mb-4 text-center">About Us</h3>
              <p>
                Cancer Management is a leading provider of modern cancer care, dedicated to advancing the fight
                against cancer through innovation, collaboration, and enhanced patient care. Our mission is to
                eradicate cancer by pushing the boundaries of scientific discovery and improving the quality of life
                for those affected.   </p>
              {/* <p className="text-center">
                <img src={checkIcon} alt="" />
              </p> */}
              <p>
                Our story began over 20 years ago when we opened our first clinic with a vision to transform
                cancer treatment. Confronted by the severe side effects and sometimes unnecessary suffering of
                conventional methods, we sought new technology and alternative approaches. This led to the
                development of our groundbreaking 12-month cancer recovery program, which integrates
                advanced treatment technology with insights from nutrition, physical training, and mental wellness.</p>
              <p>
                What sets Cancer Management apart from others is our commitment to harnessing the benefits of
                modern technology and the interaction between the soul and the body's innate healing potential.
                Our extensive research and practical experience reveal that the human body possesses remarkable
                self-healing capabilities. Our 12-month cancer recovery program is designed to activate these
                hidden resources, enhancing overall health, without side effects. Patients who engage in our
                program experience improved survival rates, increased energy levels, and a significantly enhanced
                quality of life.  </p>
              <p>
                Collaboration is at the heart of our approach. We partner with hospitals globally, delivering high-
                quality services, modern technology, and comprehensive educational programs for doctors and

                nurses. This endeavor allows us to empower healthcare professionals to elevate their level of care
                and reduce burnout. We continue to stay at the forefront of technology to integrate the latest
                advancements in cancer treatment and research in hospitals. This ensures that patients benefit
                from the most effective, cutting-edge therapies available.     </p>
              <p>
              Patients’ safety remains our utmost priority.
                            </p>
              <p>
              Are you ready to experience a transformative approach to cancer care? Contact us today, or register
in our 12-month online cancer recovery program and take the first step towards a stronger, more
energized, and healthier you.              </p>
             
            </div>
            <p className="text-center">
              <button style={{
                width: 'auto',
                height: '50px',
                backgroundColor: 'green',
                color: 'white',
                borderRadius: '3px',
                border: 'none',
                padding: "0.5rem",
                paddingTop: "0.2rem",
                paddingBottom: "0.2rem",
              }}>
                <Link to='/cancer-recovery-management-online'
                  style={{
                    textDecoration: 'none',
                    color: 'white'
                  }}>
                  Click here to fill out the form

                </Link>
              </button>
            </p>
          </div>
        </div>
      </section >
      <SecondFooter />
    </>
  );
}
