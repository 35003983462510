/* eslint-disable react-hooks/rules-of-hooks */
import ConfirmationModel from '../Modal/confirmationModel';
import './academy.css'
import React, { useState, useEffect } from 'react';

const ModalQuiz = ({ id, isOpen, onClose }) => {
    if (!isOpen) return null;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isStarted, setStarted] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [requiredError, setRequiredError] = useState(null);
    const [attemptId, setAttemptId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [isFinished, setFinished] = useState(false);
    const [resultData, setResultData] = useState(null);
    const submitLogs = async (IsModuleQuiz, moduleId) => {
        var url = `/api/Course/SubmitLog`
        // Define the parameters you want to pass in the body
        var parameters = {
            isModuleQuiz: IsModuleQuiz,
            moduleId: moduleId,
            p_ID: localStorage.getItem('MT_ID')
            // ... add more parameters as needed
        };
        var body = JSON.stringify(parameters);
        await fetch(url, {
            method: 'POST', // or 'PUT' if you are updating data
            headers: {
                'Content-Type': 'application/json'
                // Add any other headers you need here
            },
            body: body
        }).then(response => response.json())
            .then(d => {
                console.log('sadfasdf');
            })

    }

    useEffect(() => {
        if (isOpen) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const response = await fetch(`/api/Course/GetQuestions/${id}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const result = await response.json();
                    setData(result);
                    console.log()
                } catch (e) {
                    setError(e.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [isOpen]);

    const fetchAnswers = async (questionIndex = null) => {
        setLoading(true);
        if ((data && !questionIndex && data[currentQuestionIndex]) || data && questionIndex && data[questionIndex]) {
            try {
                var QuestionId = questionIndex ? data[questionIndex].questoinId : data[currentQuestionIndex].questoinId;
                const response = await fetch(`/api/Course/GetAnswers/${QuestionId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const answersData = await response.json();
                setAnswers(answersData);
                setStarted(true);
                setSelectedAnswer(null);
                setLoading(false);
            } catch (e) {
                setError(e.message);
            }
        }
    };
    const startQuizLog = async () => {
        try {
            var MTID = localStorage.getItem('MT_ID');
            const response = await fetch(`/api/Course/StartQuizLog/${id}/${MTID}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const answersData = await response.json();
            setStarted(true);
            setLoading(false);
            setAttemptId(answersData);
        } catch (e) {
            setError(e.message);
        }
    };
    useEffect(() => {
        // fetchAnswers();
    }, [data, currentQuestionIndex]);
    const handleNext = async () => {
        if (!selectedAnswer) {
            setRequiredError('Please Select The Answer to Continue..')
            return;
        }
        setRequiredError(null);
        if (currentQuestionIndex < data.length - 1) {
            var qId = data[currentQuestionIndex].questoinId;
            var aId = selectedAnswer;
            try {
                var url = `/api/Course/SubmitAnswer`
                // Define the parameters you want to pass in the body
                var parameters = {
                    answerId: aId,
                    questionId: qId,
                    id: attemptId
                    // ... add more parameters as needed
                };
                setLoading(true)
                var body = JSON.stringify(parameters);
                const response = await fetch(url, {
                    method: 'POST', // or 'PUT' if you are updating data
                    headers: {
                        'Content-Type': 'application/json'
                        // Add any other headers you need here
                    },
                    body: body
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setTimeout(() => {
                    setStarted(true);
                    setLoading(false);
                    fetchAnswers(currentQuestionIndex + 1);

                }, 500);
            } catch (e) {
                setError(e.message);
            }


        } else {
            var qId = data[currentQuestionIndex].questoinId;
            var aId = selectedAnswer;
            try {
                var url = `/api/Course/SubmitAnswer`
                // Define the parameters you want to pass in the body
                var parameters = {
                    answerId: aId,
                    questionId: qId,
                    id: attemptId
                    // ... add more parameters as needed
                };
                setLoading(true)
                var body = JSON.stringify(parameters);
                const response = await fetch(url, {
                    method: 'POST', // or 'PUT' if you are updating data
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: body
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                var userID = localStorage.getItem('MT_ID');
                if (!userID) {
                    window.location.href = '/';
                }
                const response2 = await fetch(`/api/Course/GetQuizResult/${id}/${userID}/${attemptId}`);
                if (!response2.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const answersData = await response2.json();
                setLoading(false);
                setStarted(false);
                setFinished(true);
                setSelectedAnswer(null);
                setCurrentQuestionIndex(0)
                setResultData(answersData);
            } catch (e) {
                setError(e.message);
            }

            // Handle end of quiz
        }
    };

    const startQuiz = () => {
        startQuizLog();
        fetchAnswers();
    };
    const showConfirmation = () => {
        if (attemptId) {
            setModalShow(true);
        }
        else {
            onClose()
        }
    };
    const constcloseQuizOrContinue = (event) => {
        if (event) {
            setModalShow(false);
            setAttemptId(null);
            setSelectedAnswer(null);
            setCurrentQuestionIndex(0);
            submitLogs(true, id)
            window.location.href = '/module/' + id;
        }
        else {
            setModalShow(false);
        }
    }
    return (
        <div className="modal-backdrop">
            <ConfirmationModel show={modalShow} onHide={(event) => constcloseQuizOrContinue(event)} />
            <div className="modal-content-Quiz">
                {!isFinished && <button onClick={showConfirmation} className="close-button">X</button>}
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                {data && <div style={{ position: 'relative', marginTop: '20px' }}>
                    {isStarted ? <>
                        <h5 style={{ display: 'inline-block' }}>Question No. {currentQuestionIndex + 1}</h5>
                        <p> <b>{data[currentQuestionIndex].questionDescription}</b> </p>
                        {answers.map(answer => (
                            <>
                                <label key={answer.answerId} className='answer-description'>
                                    <input
                                        className='mr-2'
                                        type="radio"
                                        checked={selectedAnswer === answer.answerId}
                                        onChange={() => setSelectedAnswer(answer.answerId)}
                                    />
                                    <span style={{ marginLeft: '3px' }}>{answer.answerDescription}</span>

                                </label>
                                <br />
                            </>

                        ))}
                        <p className='error-message'>{requiredError ? requiredError : ''}</p>
                        <button disabled={loading} className='start-button mt-2' onClick={handleNext}>Next</button>
                        <h6 style={{ float: 'right' }}>{data && data.length > 0 ? currentQuestionIndex + 1 + '/' + data.length : ''}</h6>
                    </> : isFinished ? <>                        <p className='text-center'>
                        <img src="/assets/images/winner.png" width='50' height='50' alt="" />
                        <span style={{ marginTop: '5px' }}><b >Great! You have completed the Quiz by {resultData.highestMarks} / {resultData.total} Marks </b></span> </p>
                        <div className='start-button-div'>
                            <button disabled={loading} className='start-button' onClick={() => { constcloseQuizOrContinue(true) }}>Ok</button>
                        </div>
                    </> : <>
                        <p style={{ display: 'inline-block' }}><b>Important Notice Before You Begin</b></p>
                        <p>
                            Please note that once you start the quiz, it will count as an attempt. If you leave or exit the quiz before completing it, you will not be able to continue from where you left off. Each user is allowed a maximum of three attempts. Make sure you are ready before you begin.
                        </p>
                        <div className='start-button-div'>
                            <button disabled={loading} className='start-button' onClick={() => { startQuiz() }}>Start Quiz</button>
                        </div>
                    </>}

                </div>}
            </div>
        </div>
    );
};
export default ModalQuiz;